// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-table-title {
        font-size: 20px;
        margin-bottom: 25px;
        color: #0098f7;
        font-weight: 700;
}
.table td{
    border: #0098f7 solid 2px !important;
}
.table th{
    border: #0098f7 solid 2px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/IpHistoryTable/IpHistoryTable.css"],"names":[],"mappings":"AAAA;QACQ,eAAe;QACf,mBAAmB;QACnB,cAAc;QACd,gBAAgB;AACxB;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".history-table-title {\n        font-size: 20px;\n        margin-bottom: 25px;\n        color: #0098f7;\n        font-weight: 700;\n}\n.table td{\n    border: #0098f7 solid 2px !important;\n}\n.table th{\n    border: #0098f7 solid 2px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
