import { useState,useRef,useEffect  } from 'react';
import './style.css'
import Menubar from '../../components/Menubar/Menubar';
import AdPlacementCustom from '../../components/AdPlacement/AdPlacementCustom';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';
import { MdDownload } from "react-icons/md";
import { MdUpload } from "react-icons/md";

//import JsonCsVMenuBar from '../../components/JsonCsVMenuBar/JsonCsVMenuBar';
 
const JsonToCSV = () => {
    const [jsonData, setJsonData] = useState(null);
    const [error, setError] = useState(null);
    const [isFileLoaded, setIsFileLoaded] = useState(false);
    const [fileName, setFileName] = useState('');
    const { t } = useTranslation(); 
    
    const textAreaRef = useRef(null);
    const fileInputRef = useRef(null); 
  
    const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileName(file.name);

			const reader = new FileReader();
			reader.onload = (event) => {
				const content = event.target.result;
                
				try {
					const json = JSON.parse(content);
					setJsonData(JSON.stringify(json, null, 2));
					setError(null);
					setIsFileLoaded(true);
				} catch (err) {
					setError('json_to_csv.invalid_json_text');
					setJsonData(null);
					setIsFileLoaded(false);
				}
			};
			reader.readAsText(file);
		}
	};


    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleSaveClick = () => {
        try {
			const parsedJson = JSON.parse(jsonData);
            // Function to flatten the JSON
            const flattenJson = (data, parentKey = '', result = {}) => {
                for (let key in data) {
                    const newKey = parentKey ? `${parentKey}.${key}` : key;
                    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
                        flattenJson(data[key], newKey, result);
                    } else {
                        result[newKey] = data[key];
                    }
                }
                return result;
            };

            // Function to convert JSON to CSV format
            const convertJsonToCsv = (jsonData) => {
                const flattenedData = flattenJson(jsonData);
                const csvRows = [];

                // Headers
                csvRows.push('Key,Value');

                // Data
                for (let key in flattenedData) {
                    const value = flattenedData[key].replace(/\n/g, '\\n');
                    csvRows.push(`${key},${value}`);
                }

                return csvRows.join('\n');
            };

            const csv = convertJsonToCsv(parsedJson);

            // Create a Blob from the CSV string
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            // Create a temporary link element for download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName.replace(".json", "")}.csv`);

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
            cleareJsonData()
        } catch (err) {
            console.error(err);
			setError('json_to_csv.invalid_json_text');
        }

    }

    const cleareJsonData =()=>{
        setIsFileLoaded(false);
        setJsonData(null);
        setError(null);
        setFileName('');
        fileInputRef.current.value = '';
        textAreaRef.current.value = '';
    }

    useEffect(()=>{
        document.title='JSON To CSV';
    },[])

    let adsStyle = {
		display: "inline-block",
		width: "300px",
		height: "250px",
	};

    return (
     
        <div>
			<Menubar
				searchProps={{}}
				showIPsearch={true}
			/>
			<section className="p-3 !pt-[2.5rem] bg-gradient-to-r from-[#243949] to-[#01B1BD]">
				<div
					className="p-6 max-w-5xl w-full mx-auto rounded-3xl"
					style={{background:"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",}}>
					<div className='w-full max-w-3xl mx-auto'> 
						<input type="file"  accept=".json"  ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
						<button className='bg-[#0A1A44] text-[#02B1BD] p-3 uppercase font-semibold rounded-2xl block w-full'  onClick={handleButtonClick}>
							<div className='flex items-center justify-center'>
								<div>{t('json_to_csv.upload_file_btn')}</div>
								<div className='ml-2'><MdUpload /></div>
							</div> 
						</button>
						{error && <p className='text-red-500 text-center'>{t(error)}</p>}
						<div className='p-3 w-full text-white text-base text-center text-uppercase'>
							{t('json_to_csv.insert_json_code')}
						</div>
					<div>
					</div>
				</div>
				<textarea
					ref={textAreaRef}
					rows={10}
					className='bg-white w-full h-full p-3 rounded-2xl'
					//placeholder='Add a json code!'
					value={jsonData ? jsonData : ""}
					onChange={(e) => setJsonData(e.target.value)}
				/>
				<div className='p-3 pb-0'>
					<button className='bg-[#02B1BD] p-3 uppercase font-semibold rounded-2xl block w-full shadow-sm' onClick={handleSaveClick}>
						<div className='flex items-center justify-center'>
							<div>{t('json_to_csv.export_csv')}</div>
							<div className='ml-2'><MdDownload /></div>
						</div> 
					</button>
				</div>
				</div>
			</section> 

            <section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('json_to_csv.json_to_csv_title')}
						</div>
						<img
							src="/images/jsontocsvpage/json-to-csv-page-img-1.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							{t('json_to_csv.json_to_csv_desc')}
						</div>
					</div>
					<img
						src="/images/jsontocsvpage/json-to-csv-page-img-1.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				{/* <div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					<h1>test</h1>
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div> */}
			</section>

			<Footer />
        </div>
    );
};

export default JsonToCSV;
