import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Menubar from "../../components/Menubar/Menubar";
import "./SpeedTest.css";
import AdPlacement from "../../components/AdPlacement/AdPlacement";
import AdPlacementCustom from "../../components/AdPlacement/AdPlacementCustom";
import Footer from "../../components/Footer/Footer";
import { SpeedTestViewAction } from "../../actions/googleAnalyticsActions";
import { useTranslation } from "react-i18next";

const SpeedTest = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const internetProviderDetail = JSON.parse(
		localStorage.getItem("MyIpDetail")
	);
	useEffect(()=>{
		dispatch(SpeedTestViewAction({}))
	},[])
	function DevScript() {
		const devCode = {
			sc_skin: "",
			sc_bgc: "0x123456 !important",
			sc_bc: "0x090A0A",
			sc_hc: "0xFFA333",
			sc_cc: "0x17B0E8",
			sc_w: 375,
			sc_h: 235,
			sc_userid: 108624868,
			sc_location: "IN",
			version: 3,
		};

		var sc_script = document.createElement("script");
		sc_script.setAttribute(
			"src",
			("https:" === document.location.protocol ? "https" : "http") +
				"://www.speedcheckercdn.com/speedchecker.js"
		);
		document.getElementsByTagName("head")[0].appendChild(sc_script);
		return (
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{ __html: devCode }}
			/>
		);
	}
	let adsStyle = {
		display: "inline-block",
		width: "300px",
		height: "250px",
	};
	return (
		<div>
			<Menubar />
			<section className="p-3 mt-6">
				<div className="flex flex-col gap-2 items-center w-full max-w-5xl mx-auto">
					<img
						src="/images/speed1.svg"
						alt="icon"
						className="w-40 object-contain mt-10"
					/>
					<div className="uppercase text-[#00A1C4] text-xl font-bold my-6">
						{t('general.menu_speed_test')}
					</div>
					<div className="flex flex-col md:flex-row  gap-4 mt-10">
						<img
							src="/images/speed2.svg"
							alt="icon"
							className="h-32"
						/>
						{/* <img src="/images/speed3.svg" alt="icon" className="h-32" /> */}
						<div>
							<div id="speedcheckerdiv" className="relative">
								<div
									style={{
										height: 20,
										width: 120,
										background: "white",
										position: "absolute",
										top: 0,
										right: 0,
									}}
								></div>
							</div>
							{DevScript()}
						</div>
					</div>

					<div className="mt-20 mb-8 font-semibold uppercase text-[#0A1A44]">
						{internetProviderDetail?.internet_provider}
					</div>

					<AdPlacement />
				</div>
			</section>
			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('speed_test.internet_speed_test_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-1.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.internet_speed_test_desc') }}></div>
					</div>
					<img
						src="/images/speedtest/speedtest-page-img-1.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/speedtest/speedtest-page-img-2.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('speed_test.why_need_high_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-2.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.why_need_high_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('speed_test.what_is_internet_st_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-3.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.what_is_internet_st_desc') }}></div>
					</div>
					<img
						src="/images/speedtest/speedtest-page-img-3.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/speedtest/speedtest-page-img-4.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('speed_test.performing_st_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-4.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.performing_st_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('speed_test.what_factors_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-5.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.what_factors_desc') }}></div>
					</div>
					<img
						src="/images/speedtest/speedtest-page-img-5.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/speedtest/speedtest-page-img-6.jpg"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('speed_test.perform_your_st_title')}
						</div>
						<img
							src="/images/speedtest/speedtest-page-img-6.jpg"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('speed_test.perform_your_st_desc') }}></div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default SpeedTest;
