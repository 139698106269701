import { isIPv6 } from "is-ip";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
	getIPversion6,
	GetOwnIpAction,
	IpLookupAction,
	saveMyIpDetails,
	UpdateDetailsIpInfo,
	setPreciseLocation,
	saveUserIPInfo,
	getUserIPInfoHistory,
	clearFindIpDetailAction,
} from "../../actions/ipLookupAction";
//import AdPlacement from "../../components/AdPlacement/AdPlacement";
import AdPlacementCustom from "../../components/AdPlacement/AdPlacementCustom";
//import Adsterra from "../../components/AdPlacement/Adsterra";
import Footer from "../../components/Footer/Footer";
import Menubar from "../../components/Menubar/Menubar";
import GoogleMaps from "./GoogleMaps";
import "./Home.css";
import ReactLoading from "react-loading";
import { animateScroll, Link } from "react-scroll";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { HPAddHistoryAction, HPCompleteIPDetailsAction, HPIPSearchAction, HPIPV4CopyAction, HPIPV6CopyAction, HPLoginAction, HPPreciseLocationAction, HPShowHistoryAction, HPViewsAction } from "../../actions/googleAnalyticsActions";
import { useTranslation } from "react-i18next";
//import ExternalLogger from "./ExternalLogger";
import ContentCopy from "../../images/content_copy.svg"
import CopyToClipBoard from "../../components/CopyToClipBoard";
import { checkGeolocationPermission } from "../../utils/general";

const Home = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const {
		ipLookupError,
		ipLookupData,
		ownIpAddress,
		IPVersion6,
		userIPInfoHistoryData,
		locatinPermissionStatus,
		ipLookupLoader,
	} = useSelector((state) => state.ipLookupReducer);
	const { user } = useSelector((state) => state.authReducer);
	const [ip, setIP] = useState("");
	const [Error, setError] = useState("");
	const [showError, setShowError] = useState(false);
	const [showHistoryTable, setshowHistoryTable] = useState(localStorage.getItem('loginWithShowIPHistoryClick')?true:false);
	const [cookies, setCookie] = useCookies(["ipHistory"]);
	const [isIPv4Copied, setIPv4Copied] = useState(false);
	const [isIPv6Copied, setIPv6Copied] = useState(false);
	const [showCookiesIPHistory, setShowCookiesIPHistory] = useState(false);

	const [showHistoryTooltip, setShowHisoryTooltip] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);
	const scrollRef = useRef();
	const { loginWithRedirect } = useAuth0();

	const handleLogin = async () => {
		dispatch(HPLoginAction())
		await loginWithRedirect({
			connection: "google-oauth2",
			// prompt: "login",
			// appState: {
			//     returnTo: "/redirect",
			// },
		});
	};

	const handleHistoryTableShowHide = () => {
		if (user?.email) {
			if (!showHistoryTable && user?.email) {
				dispatch(getUserIPInfoHistory(user?.email));
			}
			if(!showHistoryTable){
				dispatch(HPShowHistoryAction({}))
			}
			setshowHistoryTable(!showHistoryTable);
		} else {
			// setShowCookiesIPHistory(true);
			// setshowHistoryTable(!showHistoryTable);
			localStorage.setItem('loginWithShowIPHistoryClick',true)
			handleLogin()
		}
	};

	const storeCookies = async (ipAddress, time, location) => {
		if (user?.email) {
			await dispatch(
				saveUserIPInfo({ ipAddress, time, location }, user?.email)
			);
			await dispatch(getUserIPInfoHistory(user?.email));
			await setshowHistoryTable(true);
		} else {
			handleLogin();
		}
		/*if (!cookies?.ipHistory)
        setCookie("ipHistory", [{ ipAddress, time, location }], { path: "/" });
      else
        setCookie(
          "ipHistory",
          [{ ipAddress, time, location }, ...cookies?.ipHistory],
          { path: "/" }
        );*/
	};

	const successCallback = (position) => {
		const { latitude, longitude } = position.coords;
		if (ipLookupData?.location) {
			let tempLocation = ipLookupData;
			tempLocation.location.latitude = latitude;
			tempLocation.location.longitude = longitude;
			dispatch(setPreciseLocation(tempLocation));
		}
	};
	const errorCallback = (error) => {};

	const handleclickPriciseLocation = async (e) => {
		dispatch(HPPreciseLocationAction());
		// if ("geolocation" in navigator) {
		// 	navigator.geolocation.getCurrentPosition(
		// 		successCallback,
		// 		errorCallback,
		// 		{ enableHighAccuracy: true }
		// 	);
		// } else {
		// 	//console.log('Geolocation is not available in your browser.');
		// }

		const permission_status = await checkGeolocationPermission();
		if (permission_status === 'granted') {
			navigator.geolocation.getCurrentPosition(
				successCallback,
				errorCallback,
				{ enableHighAccuracy: true }
			);
		}
	};

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		window
			.matchMedia("(orientation: portrait)")
			.addEventListener("change", (e) => {
				setWidth(window.innerWidth - (window.innerWidth * 20) / 100);
			});
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);

	useEffect(() => {
		dispatch(HPViewsAction({}));
		if (!Object.keys(ownIpAddress).length) {
			dispatch(GetOwnIpAction());
			dispatch(getIPversion6());
		}
		if(localStorage.getItem('loginWithShowIPHistoryClick')){
			dispatch(getUserIPInfoHistory(user?.email));
			localStorage.removeItem('loginWithShowIPHistoryClick')
		}
		return ()=>{
			/**START: Clear Manually Find Ip Details */
			dispatch(IpLookupAction((ownIpAddress.IPv4 && ownIpAddress.IPv4) ||(IPVersion6 && IPVersion6)));
			dispatch(clearFindIpDetailAction()); 
			/**END: Clear Manually Find Ip Details */
		}
	}, []);

	useEffect(() => {
		const data = localStorage.getItem("MyIpDetail");
		if (!!!data) {
			dispatch(saveMyIpDetails(ownIpAddress.IPv4));
		}
	}, [ipLookupData]);

	useEffect(() => {
		if (
			Object.keys(ownIpAddress).length &&
			!Object.keys(ipLookupData).length
		) {
			dispatch(
				IpLookupAction(
					(ownIpAddress.IPv4 && ownIpAddress.IPv4) ||
						(isIPv6(IPVersion6) && IPVersion6)
				)
			);
		}
	}, [ownIpAddress]);

	useEffect(() => {
		if (ipLookupError) {
			setError(ipLookupError);
		}
		setShowError(true);
	}, [ipLookupError]);

	const submitHandler = () => {
		dispatch(HPCompleteIPDetailsAction({}));
		setShowDetails(true);
		dispatch(UpdateDetailsIpInfo(ownIpAddress.IPv4));
	};
	const handleSearchButton = (e) => {
		e.preventDefault();
		if (!ip) {
			dispatch(IpLookupAction( (ownIpAddress.IPv4 && ownIpAddress.IPv4) || (isIPv6(IPVersion6) && IPVersion6) ));
			setShowError(true);
			setError("Please enter a valid IP address...!");
		} else { 
			dispatch(IpLookupAction(ip,true));
			setShowDetails(true);
			dispatch(UpdateDetailsIpInfo(ip));
		}
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIPv4Copied(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [isIPv4Copied]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIPv6Copied(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [isIPv6Copied]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowHisoryTooltip(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [showHistoryTooltip]);

	let adsStyle = {
		display: "inline-block",
		width: "300px",
		height: "250px",
	};

	return (
		<div>
			<Menubar
				searchProps={{
					value: ip,
					onChange: (event) => {
						setIP(event.target.value);
						setShowError(false);
					},
					submit: (e) => {
						dispatch(HPIPSearchAction())
						handleSearchButton(e);
						animateScroll.scrollTo(
							scrollRef?.current?.getBoundingClientRect().top
						);
					},
				}}
				showIPsearch={true}
			/>

			<section className="p-3 bg-gradient-to-r from-[#243949] to-[#01B1BD]">
				<div className="flex mx-auto sm:max-w-5xl md:px-10 px-[0px] my-4">
					<form
						className="topForm rounded-lg bg-white px-3 py-2 position-relative w-full"
						onSubmit={(e) => {
							dispatch(HPIPSearchAction())
							handleSearchButton(e);
							animateScroll.scrollTo(
								scrollRef?.current?.getBoundingClientRect().top
							);
						}}
					>
						<input
							type="text"
							className="bg-transparent border-none outline-none w-full"
							placeholder={t('general.enter_ip_address')}
							value={ip}
							onChange={(event) => {
								setIP(event.target.value);
								setShowError(false);
							}}
						/>
						<button type="submit">
							<img
								src="/icons/search.svg"
								alt="icon"
								className="button cursor-pointer"
							/>
						</button>
					</form>
				</div>
				<div
					className="flex flex-col p-6 max-w-5xl w-full mx-auto rounded-3xl"
					style={{
						background:
							"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",
					}}
				>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-3">
						<div className="flex flex-col gap-2">
							<h1 className="font-semibold uppercase">
								{t('home.my_ip_address')}
							</h1>
							<div
								className="flex flex-col rounded-2xl gap-1 flex-1"
								style={{
									background:
										"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",
								}}
							>
								<div className="flex flex-row gap-2 p-3 border-b-2 border-[#5D9FA8] flex-1 items-start md:items-center">
									<div className="font-semibold">{t('general.ipv4')}:</div>
									<div className="text-[#4A5571]">
										{ipLookupData.IPv4 && !ipLookupData.IPv6
											? ipLookupData.IPv4
											: ownIpAddress.IPv4 &&
											  !ipLookupData.IPv4 &&
											  !ipLookupData.IPv6
											? ownIpAddress.IPv4
											: "Not Detected"}
									</div>
									{ipLookupData.IPv4 || ownIpAddress.IPv4 ? (
										<OverlayTrigger
											placement={"top"}
											overlay={
												<Tooltip
													id={`tooltip-top`}
													style={{
														width: "150px",
														textAlign: "center",
													}}
												>
													{isIPv4Copied
														? "Copied!"
														: "Copy to clipboard"}
												</Tooltip>
											}
										>
											<svg
												width="43"
												height="50"
												viewBox="0 0 43 50"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="h-auto w-4 cursor-pointer md:mt-0 mt-[2px] flex-[0_0_auto]"
												onClick={() => {
													navigator.clipboard.writeText(
														ipLookupData.IPv4
															? ipLookupData.IPv4
															: ownIpAddress.IPv4
													);
													dispatch(HPIPV4CopyAction())
													setIPv4Copied(true);
												}}
											>
												<path d="M15.5 40C14.125 40 12.9479 39.5104 11.9688 38.5312C10.9896 37.5521 10.5 36.375 10.5 35V5C10.5 3.625 10.9896 2.44792 11.9688 1.46875C12.9479 0.489583 14.125 0 15.5 0H38C39.375 0 40.5521 0.489583 41.5312 1.46875C42.5104 2.44792 43 3.625 43 5V35C43 36.375 42.5104 37.5521 41.5312 38.5312C40.5521 39.5104 39.375 40 38 40H15.5ZM15.5 35H38V5H15.5V35ZM5.5 50C4.125 50 2.94792 49.5104 1.96875 48.5312C0.989583 47.5521 0.5 46.375 0.5 45V10H5.5V45H33V50H5.5Z" fill="#4A5571"/>
											</svg>
										</OverlayTrigger>
									) : (<div></div>)}
								</div>
								<div className="flex flex-row gap-2 p-3 flex-1 items-start md:items-center">
									<div className="font-semibold">{t('general.ipv6')}:</div>
									<div className="text-[#4A5571] break-all">
										{(ipLookupData.IPv6 &&
											ipLookupData.IPv6) ||
											(isIPv6(IPVersion6) &&
												IPVersion6) ||
											t('general.not_detected')}
									</div>
									{ipLookupData.IPv6 ||
									(isIPv6(IPVersion6) && IPVersion6) ? (
										<OverlayTrigger
											placement={"top"}
											overlay={
												<Tooltip
													id={`tooltip-top`}
													style={{
														width: "150px",
														textAlign: "center",
													}}
												>
													{isIPv6Copied
														? "Copied!"
														: "Copy to clipboard"}
												</Tooltip>
											}
										>
											<svg
												width="43"
												height="50"
												viewBox="0 0 43 50"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												className="h-5 w-auto cursor-pointer md:mt-0 mt-[2px] flex-[0_0_auto]"
												onClick={() => {
													navigator.clipboard.writeText(
														(ipLookupData.IPv6 &&
															ipLookupData.IPv6) ||
															(isIPv6(
																IPVersion6
															) &&
																IPVersion6 &&
																IPVersion6) ||
															"Not Detected"
													);
													dispatch(HPIPV6CopyAction())
													setIPv6Copied(true);
												}}
											>
												<path d="M15.5 40C14.125 40 12.9479 39.5104 11.9688 38.5312C10.9896 37.5521 10.5 36.375 10.5 35V5C10.5 3.625 10.9896 2.44792 11.9688 1.46875C12.9479 0.489583 14.125 0 15.5 0H38C39.375 0 40.5521 0.489583 41.5312 1.46875C42.5104 2.44792 43 3.625 43 5V35C43 36.375 42.5104 37.5521 41.5312 38.5312C40.5521 39.5104 39.375 40 38 40H15.5ZM15.5 35H38V5H15.5V35ZM5.5 50C4.125 50 2.94792 49.5104 1.96875 48.5312C0.989583 47.5521 0.5 46.375 0.5 45V10H5.5V45H33V50H5.5Z" fill="#4A5571"/>
											</svg>
										</OverlayTrigger>
									) : (<div></div>)}
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<h1 className="font-semibold uppercase">
								{t('home.my_ip_info')}
							</h1>
							<div
								className="flex flex-col p-3 rounded-2xl flex-1"
								style={{
									background:
										"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",
								}}
							>
								<table>
									<tbody>
										<tr>
											<td className="font-semibold p-2 flex">
												{t('home.isp')}:
											</td>
											<td className="text-[#4A5571] p-2">
												{ipLookupData.internet_provider}
											</td>
										</tr>
										<tr>
											<td className="font-semibold p-2 flex">
												{t('general.city')}:
											</td>
											<td className="text-[#4A5571] p-2">
												{ipLookupData.City}
											</td>
										</tr>
										<tr>
											<td className="font-semibold p-2 flex">
												{t('general.region')}:
											</td>
											<td className="text-[#4A5571] p-2">
												{ipLookupData.State}
											</td>
										</tr>
										<tr>
											<td className="font-semibold p-2 flex">
												{t('general.country')}:
											</td>
											<td className="text-[#4A5571] p-2">
												{ipLookupData.Country}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							{/* <div className="text-sm">
                <span className="text-transparent">-----</span>
              </div> */}
						</div>
						<div className="flex flex-col gap-2">
							<div className="flex justify-between items-center	">
								<h1 className="font-semibold uppercase">
									{t('home.map_location')}:
								</h1>
								{!locatinPermissionStatus &&
									ipLookupData?.location?.latitude &&
									ipLookupData?.location?.longitude && (
										<span
											className="underline cursor-pointer text-xs"
											onClick={handleclickPriciseLocation}
										>
											{" "}
											{t('home.show_precise_location')}
										</span>
									)}
							</div>
							<div
								className="flex flex-col rounded-2xl border-2 border-white sm:flex-1 overflow-hidden h-60 sm:h-auto"
								style={{
									background:
										"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",
								}}
							>
								{ipLookupLoader ? (
									<ReactLoading
										type={"spin"}
										color={"#333"}
										height={25}
										width={25}
										style={{
											textAlign: "center",
											margin: "80px 130px",
											width: "30px",
											height: "30px",
										}}
									/>
								) : (
									ipLookupData?.location?.latitude &&
									ipLookupData?.location?.longitude && (
										<GoogleMaps
											lat={
												ipLookupData?.location?.latitude
											}
											lng={
												ipLookupData?.location
													?.longitude
											}
											key={`location_precise_${locatinPermissionStatus}`}
										/>
									)
								)}
							</div>

							{/* <div className="text-sm">
                <span className="text-[#4A5571]">Location not accurate?</span>
              </div> */}
						</div>
					</div>
					{ipLookupData?.location?.latitude &&
						ipLookupData?.location?.longitude && (
							<div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-1">
								<div className="flex flex-col gap-2"></div>
								<div className="flex flex-col gap-2"></div>
								<div className="flex flex-col gap-2">
									<div className="flex justify-between items-end">
										<h5 className="font-semibold">
											{t('general.latitude')}:{" "}
											{ipLookupData?.location?.latitude
												? ipLookupData?.location?.latitude.toFixed(
														4
												  )
												: " "}
										</h5>
										<h5 className="font-semibold">
											{t('general.longitude')}:{" "}
											{ipLookupData?.location?.longitude
												? ipLookupData?.location?.longitude.toFixed(
														4
												  )
												: " "}
										</h5>
									</div>
								</div>
							</div>
						)}

					<div className="my-8 w-full border-b-2 border-[#C2EFF3]"></div>

					<div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mx-auto">
						<button
							onClick={handleHistoryTableShowHide}
							className="flex-1 bg-[#02B1BD] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center"
						>
							{showHistoryTable ? `${t('general.hide')} ` : `${t('general.show')} `}{t('home.my_ip_history')}
							<img
								src="/icons/history.svg"
								alt="icon"
								className="h-6"
							/>
						</button>
						<OverlayTrigger
							placement={"top"}
							overlay={
								<Tooltip
									id={`tooltip-top`}
									style={{
										width: "150px",
										textAlign: "center",
										display: showHistoryTooltip
											? "block"
											: "none",
									}}
								>
									{t('home.ip_added_to_history')}
								</Tooltip>
							}
						>
							<button
								onClick={() => {
									storeCookies(
										{
											IPv4: ip
												? ip
												: ownIpAddress.IPv4 &&
												  ownIpAddress.IPv4,
											IPv6:
												(isIPv6(IPVersion6) &&
													IPVersion6) ||
												t('general.not_detected'),
										},
										+new Date(),
										`${ipLookupData.City?ipLookupData.City+',':''}${ipLookupData.Country?ipLookupData.Country:''}`
									);
									dispatch(HPAddHistoryAction({}))
									setShowHisoryTooltip(true);
								}}
								className="flex-1 bg-[#0A1A44] text-[#02B1BD] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center"
							>
								{t('home.add_ip_history')}
								<img
									src="/icons/add.svg"
									alt="icon"
									className="h-6"
								/>
							</button>
						</OverlayTrigger>
					</div>

					{showHistoryTable ? (
						<IpHistoryTable
							cookies={
								showCookiesIPHistory
									? cookies
									: { ipHistory: userIPInfoHistoryData }
							}
							showCookiesIPHistory={showCookiesIPHistory}
							setCookie={setCookie}
						/>
					) : (
						""
					)}
				</div>

				<div className="flex flex-col my-10 justify-center items-center max-w-5xl w-full mx-auto">
					<p className="text-center text-sm uppercase text-white">
						{t('home.show_complete_ip_details')}
					</p>
					<Link
						to="ad1"
						spy={true}
						smooth={true}
						duration={0}
						delay={0}
					>
						<img
							onClick={submitHandler}
							src="/icons/down.svg"
							alt="icon"
							className="h-10 my-3 cursor-pointer"
						/>
					</Link>
					{/* {showDetails && <Adsterra width={width} />} */}
					{/* <div id="ad1" className="w-full" ref={scrollRef}> */}
					{/* <AdPlacementCustom /> */}
					{/* </div> */}
				</div>
				{/* <ExternalLogger /> */}
				<div id="ipDetails">
					{showDetails && (
						<IpDetails
							ipv6={
								(ipLookupData.IPv6 && ipLookupData.IPv6) ||
								(isIPv6(IPVersion6) &&
									IPVersion6 &&
									IPVersion6) ||
								t('general.not_detected')
							}
						/>
					)}{" "}
					{showError && (
						<div className="text-red-200 text-center">{Error}</div>
					)}
				</div>
				{/*showDetails && (
					<div className="flex flex-col my-10 justify-center items-center max-w-5xl w-full mx-auto">
						<Adsterra width={width} />
					</div>
				)*/}
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('home.comprehensive_guide_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-1.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							{t('home.comprehensive_guide_desc')}
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-1.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-2.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('home.ip_address_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-2.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.ip_address_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('home.how_ip_work_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-3.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.how_ip_work_desc') }}></div>
					</div>
					<img
						src="/images/homepage/home-page-img-3.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-4.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('home.change_ip_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-4.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.change_ip_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('home.diff_ip_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-5.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.diff_ip_desc') }}></div>
					</div>
					<img
						src="/images/homepage/home-page-img-5.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-6.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('home.ip_without_permission_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-6.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.ip_without_permission_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('home.hide_ip_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-7.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.hide_ip_desc') }}></div>
					</div>
					<img
						src="/images/homepage/home-page-img-7.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-8.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('home.benefit_of_vpn_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-8.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.benefit_of_vpn_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('home.how_choose_vpn_title')}
						</div>
						<img
							src="/images/homepage/home-page-img-9.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('home.how_choose_vpn_desc') }}></div>
					</div>
					<img
						src="/images/homepage/home-page-img-9.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			{/* <section className="px-3 py-20 bg-white md:hidden"> */}
			<section className="px-3 py-20 bg-white ">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('home.conclusion_title')}
						</div>
						<div dangerouslySetInnerHTML={{ __html: t('home.conclusion_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<Footer />
		</div>
	);
};

const IpDetails = ({ ips, ipv6 = null }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isIPv4Copied, setIPv4Copied] = useState(false);
	const [isIPv6Copied, setIPv6Copied] = useState(false);
	const { ipLookupLoader, ipLookupData, findIpDetail,locatinPermissionStatus } = useSelector(
		(state) => state.ipLookupReducer
	);

	useEffect(() => {
		if (findIpDetail) {
			dispatch(IpLookupAction(findIpDetail,true)); //Manual IP search
		} else if (ips) {
			dispatch(IpLookupAction(ips)); //Own IP search.
		}
	}, [findIpDetail, ips]);

	function toDegreesMinutesAndSeconds(coordinate) {
		var absolute = Math.abs(coordinate);
		var degrees = Math.floor(absolute);
		var minutesNotTruncated = (absolute - degrees) * 60;
		var minutes = Math.floor(minutesNotTruncated);
		var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
		return degrees + "° " + minutes + "′ " + seconds + "″ ";
	}

	function latitudeConvertDMS(lat) {
		const latitude = toDegreesMinutesAndSeconds(lat);
		const latitudeCardinal = lat >= 0 ? "N" : "S";
		return latitude + " " + latitudeCardinal;
	}
	function longitudeConvertDMS(lng) {
		const longitude = toDegreesMinutesAndSeconds(lng);
		const longitudeCardinal = lng >= 0 ? "E" : "W";
		return longitude + " " + longitudeCardinal;
	}

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIPv4Copied(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [isIPv4Copied]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIPv6Copied(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [isIPv6Copied]);

	if (ipLookupLoader) {
		return (
			<div className="loading__icon">
				<ReactLoading
					type={"spin"}
					color={"#333"}
					height={25}
					width={25}
					style={{
						textAlign: "center",
						margin: "0 auto",
						width: "30px",
						height: "30px",
					}}
				/>
			</div>
		);
	}
	return (
		<div className="my-10 grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl mx-auto">
			<div className="col-span-1 md:col-span-2 rounded-3xl overflow-hidden home_card_3">
				<div className="p-3 px-8 uppercase text-white bg-[#0A1A44] flex flex-row items-center gap-3">
					{t('home.ip_details_for_ipv4')}: {findIpDetail || ips}
					<OverlayTrigger
						placement={"top"}
						overlay={
							<Tooltip
								id={`tooltip-top`}
								style={{ width: "150px", textAlign: "center" }}
							>
								{isIPv4Copied ? "Copied!" : "Copy to clipboard"}
							</Tooltip>
						}
					>
						<img
							onClick={() => {
								navigator.clipboard.writeText(
									findIpDetail || ips
								);
								dispatch(HPIPV4CopyAction()) 
								setIPv4Copied(true);
							}}
							src="/icons/copy.svg"
							alt="icon"
							className="h-4 cursor-pointer"
						/>
					</OverlayTrigger>
					{ipv6 && (
						<>
							{"IPv6: " + ipv6}
							<OverlayTrigger
								placement={"top"}
								overlay={
									<Tooltip
										id={`tooltip-top`}
										style={{
											width: "150px",
											textAlign: "center",
										}}
									>
										{isIPv6Copied
											? "Copied!"
											: "Copy to clipboard"}
									</Tooltip>
								}
							>
								<img
									onClick={() => {
										navigator.clipboard.writeText(ipv6);
										dispatch(HPIPV6CopyAction()) 
										setIPv6Copied(true);
									}}
									src="/icons/copy.svg"
									alt="icon"
									className="h-4 cursor-pointer"
								/>
							</OverlayTrigger>
						</>
					)}
				</div>

				{Object.keys(ipLookupData).length <= 1 ? (
					<div className="no-data">
						<p> No data found for {findIpDetail || ips} address </p>
					</div>
				) : (
					<div className="flex flex-col gap-4 p-3 text-sm px-8">
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.decimal')}:{" "}
							</span>
							{ipLookupData?.decimal}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.hostname')}:{" "}
							</span>
							{ipLookupData?.hostname}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
							{t('general.asn')}:{" "}
							</span>
							{ipLookupData?.ASN}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.isp')}:{" "}
							</span>
							{ipLookupData?.internet_provider}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.services')}:{" "}
							</span>
							{t('general.none_detected')}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.assignment')}:{" "}
							</span>
							{t('general.likely_static_ip')}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.country')}:{" "}
							</span>
							{ipLookupData?.Country}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.state')}/{t('general.region')}:{" "}
							</span>
							{ipLookupData?.State}
						</p>
						<p className="text-[#4A5571]">
							<span className="font-semibold text-black">
								{t('general.city')}:{" "}
							</span>
							{ipLookupData?.City}
						</p>
					</div>
				)}
			</div>
			<div className="rounded-3xl overflow-hidden home_card_3 flex flex-col gap-4 text-sm p-6">
				<div className="rounded-2xl overflow-hidden h-60">
					{ipLookupData?.location?.latitude &&
						ipLookupData?.location?.longitude && (
							<GoogleMaps
								lat={ipLookupData?.location?.latitude}
								lng={ipLookupData?.location?.longitude}
								key={`location_precise_${locatinPermissionStatus}`} 
							/>
						)}
				</div>
				<p className="text-[#4A5571]">
					<span className="font-semibold text-black">{t('general.latitude')}: </span>
					{ipLookupData?.location?.latitude +
						" (" +
						latitudeConvertDMS(ipLookupData?.location?.latitude) +
						")"}
				</p>
				<p className="text-[#4A5571]">
					<span className="font-semibold text-black">
						{t('general.longitude')}:{" "}
					</span>
					{ipLookupData?.location?.longitude +
						" (" +
						longitudeConvertDMS(ipLookupData?.location?.longitude) +
						")"}
				</p>
			</div>
		</div>
	);
};

const IpHistoryTable = ({ cookies, showCookiesIPHistory }) => {
	const { t } = useTranslation();
	const timeStampToDateTime = (timestamp) =>
		new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		}).format(timestamp);

	return (
		<div className="history-table">
			{cookies?.ipHistory?.length > 0 ? (
				<div className="flex flex-row overflow-hidden w-full">
					<div className="flex flex-row overflow-auto w-full">
						<table>
							<thead>
								<tr>
									<th className="md:min-w-[auto] min-w-[250px]" style={{ textTransform: "uppercase" }}>{t('general.ip_address')}</th>
									<th style={{ textTransform: "uppercase" }}>{t('general.time')}</th>
									<th style={{ textTransform: "uppercase" }}>{t('general.location')}</th>
								</tr>
							</thead>
							<tbody>
								{cookies?.ipHistory?.slice(0, 10).map((e) => {
									if (showCookiesIPHistory) {
										return (
											<tr>
												<td className="">
													<p className="flex items-start gap-1">
														<strong>{t('general.ipv4')}</strong>:
														<span className="inline-block break-all">
															{e?.ipAddress?.IPv4}
															{
																e?.ipAddress?.IPv4 !== "Not Detected" && (
																	<CopyToClipBoard
																		triggerEl={<img alt="copy icon" src={ContentCopy} width={42} height={50} className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1" />}
																		data={e?.ipAddress?.IPv4}
																	/>
																)
															}
														</span>
													</p>
													<p className="flex items-start gap-1">
														<strong>{t('general.ipv6')}</strong>:
														<span className="inline-block break-all">
															{e?.ipAddress?.IPv6}
															{
																e?.ipAddress?.IPv6 !== "Not Detected" && (
																	<CopyToClipBoard
																		triggerEl={<img alt="copy icon" src={ContentCopy} width={42} height={50} className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1" />}
																		data={e?.ipAddress?.IPv6}
																	/>
																)
															}
														</span>
													</p>
												</td>
												<td className="break-words">
													{timeStampToDateTime(
														e?.time
													)}
												</td>
												<td className="break-words">
													{e?.location}
												</td>
											</tr>
										);
									} else {
										let newData = JSON.parse(e.ipdetails);
										return (
											<tr>
												<td style={{ textAlign: "left" }}>
													<p className="flex items-start gap-1">
														<strong>{t('general.ipv4')}:</strong>
														<span className="flex break-all">
															{newData?.ipAddress?.IPv4}
															{
																newData?.ipAddress?.IPv4 !== "Not Detected" && (
																	<CopyToClipBoard
																		triggerEl={<img alt="copy icon" src={ContentCopy} width={42} height={50} className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1" />}
																		data={newData?.ipAddress?.IPv4}
																	/>
																)
															}
														</span>
													</p>
													<p className="flex items-start gap-1">
														<strong>{t('general.ipv6')}:</strong>
														<span className="flex break-all">
															{newData?.ipAddress?.IPv6}
															{
																newData?.ipAddress?.IPv6 !== "Not Detected" && (
																	<CopyToClipBoard
																		triggerEl={<img alt="copy icon" src={ContentCopy} width={42} height={50} className="w-3 h-auto inline-block ml-2 cursor-pointer mb-1" />}
																		data={newData?.ipAddress?.IPv6}
																	/>
																)
															}
														</span>
													</p>
												</td>
												<td className="break-words">
													{timeStampToDateTime(
														newData?.time
													)}
												</td>
												<td className="break-words">
													{newData?.location}
												</td>
											</tr>
										);
									}
								})}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default Home;
