import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Menubar from "../../components/Menubar/Menubar";
import "./IpDetails.css";
import adplacementnew from "../../images/ad-placement-new.png";
import map from "../../images/map.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { IpLookupAction } from "../../actions/ipLookupAction";
import ReactLoading from "react-loading";
import GoogleMaps from "../Home/GoogleMaps";

const IpDetails = ({ ips }) => {
  const dispatch = useDispatch();
  const { ipLookupLoader, ipLookupData, findIpDetail } = useSelector(
    (state) => state.ipLookupReducer
  );

  useEffect(() => {
    if (findIpDetail) {
      dispatch(IpLookupAction(findIpDetail));
    } else if (ips) {
      dispatch(IpLookupAction(ips));
    }
  }, [findIpDetail, ips]);

  function toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    return degrees + "° " + minutes + "′ " + seconds + "″ ";
  }

  function latitudeConvertDMS(lat) {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? "N" : "S";
    return latitude + " " + latitudeCardinal;
  }
  function longitudeConvertDMS(lng) {
    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? "E" : "W";
    return longitude + " " + longitudeCardinal;
  }
  return (
    <div>
      {ipLookupLoader ? (
        <div className="loading__icon">
          <ReactLoading
            type={"spin"}
            color={"#333"}
            height={25}
            width={25}
            style={{
              textAlign: "center",
              margin: "0 auto",
              width: "30px",
              height: "30px",
            }}
          />
        </div>
      ) : (
        <div>
          {ips ? <Menubar /> : ""}
          <h4 className="text-center mb-4">
            IP Details For : {findIpDetail || ips}
          </h4>
          {Object.keys(ipLookupData).length <= 1 ? (
            <div className="no-data">
              <p> No data found for {findIpDetail || ips} address </p>
            </div>
          ) : (
            <Container className="ipdetail mb-4">
              <div className="ip-box">
                <Row>
                  <Col lg={8}>
                    <div className="ip-box-wrap-all">
                      <div className="ip-box-wrap">
                        <div className="ip-box-label">Decimal : </div>
                        <div className="ip-box-text">
                          {ipLookupData?.decimal}
                        </div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">Hostname : </div>
                        <div className="ip-box-text">
                          {ipLookupData?.hostname}
                        </div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">ASN : </div>
                        <div className="ip-box-text">{ipLookupData?.ASN}</div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">ISP : </div>
                        <div className="ip-box-text">{ipLookupData?.ISP} </div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">Services : </div>
                        <div className="ip-box-text">None detected</div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">Assignment : </div>
                        <div className="ip-box-text">Likely Static IP</div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">Country : </div>
                        <div className="ip-box-text">
                          {" "}
                          {ipLookupData?.Country}
                        </div>
                      </div>

                      <div className="ip-box-wrap">
                        <div className="ip-box-label">State/Region : </div>
                        <div className="ip-box-text">
                          {" "}
                          {ipLookupData?.State}
                        </div>
                      </div>
                      <div className="ip-box-wrap">
                        <div className="ip-box-label">City : </div>
                        <div className="ip-box-text"> {ipLookupData?.City}</div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-4 add-image">
                      <img src={adplacementnew} alt="" />
                    </div>
                    <div className="mb-4">
                      {ipLookupData?.location?.latitude &&
                        ipLookupData?.location?.longitude && (
                          <GoogleMaps
                            lat={ipLookupData?.location?.latitude}
                            lng={ipLookupData?.location?.longitude}
                          />
                        )}
                    </div>
                    <div>
                      <div className="ip-box-wrap-latitude">
                        <div className="ip-box-wrap">
                          <div className="ip-box-label">Latitude:</div>
                          <div className="ip-box-text">
                            {ipLookupData?.location?.latitude +
                              " (" +
                              latitudeConvertDMS(
                                ipLookupData?.location?.latitude
                              ) +
                              ")"}
                          </div>
                        </div>
                        <div className="ip-box-wrap">
                          <div className="ip-box-label">Longitude: </div>
                          <div className="ip-box-text">
                            {ipLookupData?.location?.longitude +
                              " (" +
                              longitudeConvertDMS(
                                ipLookupData?.location?.longitude
                              ) +
                              ")"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )}
        </div>
      )}
    </div>
  );
};

export default IpDetails;
