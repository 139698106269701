import React, { useEffect, useState } from 'react'
import en from "../../images/en-us.svg"
import ru from "../../images/ru.svg"
import { useTranslation } from 'react-i18next'

const languages = [
    {
        label: 'English',
        value: 'en-US',
        icon: en
    },
    {
        label: 'Русский',
        value: 'ru',
        icon: ru
    }
]

const LanguageSwitcher = ({ isMobile = false }) => {
    const { i18n } = useTranslation();
    let languageFromLocal = localStorage.getItem("i18nextLng");
    if (languageFromLocal === "en") {
        languageFromLocal = "en-US"
    }
    
    const langObj = languages.find((item) => item.value === languageFromLocal);
    const [currLang, setCurrLang] = useState(langObj || {
        label: 'English',
        value: 'en-US',
        icon: en
    });
    const [IsLangMenuOpen, setLangMenuOpen] = useState(false);

    useEffect(() => {
        if (currLang && currLang.value) {
            i18n.changeLanguage(currLang.value);
            setLangMenuOpen(false);
        }
    }, [currLang])

    if (isMobile) {
        return (
            <ul className='flex px-[17px] gap-3'>
                {
                    languages?.map((lang, index) => {
                        return (
                            <li key={index} className={`border-solid border-[1px]${currLang.value === lang.value ? ' border-black' : ''}`} onClick={() => setCurrLang(lang)}>
                                <img alt={lang.value} width={30} height={16} className='h-4 w-[30px] object-cover' src={lang.icon} />
                            </li>   
                        )
                    })
                }
            </ul>
        );
    }

    return (
        <div className="relative pr-2 mr-2 border-r-2 border-solid border-brand w-fit z-10">
            <div
                className={`flex gap-2 transition-all cursor-pointer items-center`}
                onClick={() => setLangMenuOpen(!IsLangMenuOpen)}
            >
                <img alt={currLang?.value} width={30} height={16} src={currLang?.icon} className='h-4 w-[30px] object-cover' />
                <div className="leading-[24px] inline-flex w-fit underline">{currLang?.label}</div>
            </div>
            {
                IsLangMenuOpen && (
                    <ul className="absolute top-full -left-10 translate-y-2 p-4 rounded-lg min-w-[200px] bg-gradient-to-l from-[#8BD4DB] to-[#9AACB3] shadow-[0_4px_4px_rgba(0,0,0,0.25)] [&>*]:pb-4 [&>*]:mb-4 [&>*]:border-b [&>*]:border-solid [&>*]:border-[#5C9FA8] [&>*:last-of-type]:border-none [&>*:last-of-type]:mb-0 [&>*:last-of-type]:pb-0 [&>*]:text-[#4A5571] [&>*]:font-bold [&>*]:cursor-pointer">
                        {
                            languages?.map((lang, index) => {
                            return (
                                <li key={index} className="flex items-center gap-2" onClick={() => setCurrLang(lang)}>
                                    <img alt={lang.value} width={30} height={16} src={lang.icon} />
                                    <span className="leading-none inline-block">{lang.label}</span>
                                </li>   
                            )
                            })
                        }
                    </ul>
                )
            }
        </div>
    )
}

export default LanguageSwitcher