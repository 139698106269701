// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jsonUpdatePage{
  background: #eef1f1 ; 
} 
.json-data-row td:nth-child(2),.json-data-row td:nth-child(3) {
  width: 33.33%;
}

.json-data-row td{
  width: 10%;
}`, "",{"version":3,"sources":["webpack://./src/views/JsonToCSV/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".jsonUpdatePage{\n  background: #eef1f1 ; \n} \n.json-data-row td:nth-child(2),.json-data-row td:nth-child(3) {\n  width: 33.33%;\n}\n\n.json-data-row td{\n  width: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
