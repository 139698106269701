import React, { useState, useRef, useCallback, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { useCookies } from "react-cookie";

import Menubar from "../../components/Menubar/Menubar";
//import Adsterra from "../../components/AdPlacement/Adsterra";
import Footer from "../../components/Footer/Footer";
// import TestAdsterra from "./TestAdsterra";
// import ReactLoading from "react-loading";

const Test = () => {
	const [cookies, setCookie] = useCookies("userIdSearch");
	const [userId, setUserId] = useState(
		cookies?.userIdSearch ? cookies?.userIdSearch : ""
	);
	const [, updateState] = useState();
	const [width, setWidth] = useState(window.innerWidth);
	const [refreshLoader, setRefreshLoader] = useState(false);
	const [hideIframe, setHideIframe] = useState(true);

	const scrollRef = useRef();

	const forceUpdate = useCallback(() => updateState({}), []);

	const handleSearchButton = (e) => {
		e.preventDefault();
		setCookie("userIdSearch", userId, { path: "/" });
	};

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		window
			.matchMedia("(orientation: portrait)")
			.addEventListener("change", (e) => {
				setWidth(window.innerWidth - (window.innerWidth * 20) / 100);
			});
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);

	const handleRefreshClick = () => {
		setHideIframe(false);
		setRefreshLoader(true);
		setTimeout(() => {
			setRefreshLoader(false);
			forceUpdate();
		}, 200);
	};
	return (
		<div>
			<Menubar
				searchProps={{
					value: userId,
					onChange: (event) => {
						setHideIframe(true);
						setUserId(event.target.value);
						setCookie("userIdSearch", event.target.value, {
							path: "/",
						});
					},
					submit: (e) => {
						handleSearchButton(e);
						animateScroll.scrollTo(
							scrollRef?.current?.getBoundingClientRect().top
						);
					},
					isUserIdSearch: true,
				}}
				showIPsearch={true}
			/>

			<section className="p-3 bg-gradient-to-r from-[#243949] to-[#01B1BD]">
				<div
					className="flex flex-col p-6 max-w-5xl w-full mx-auto rounded-3xl mt-6"
					style={{
						background:
							"linear-gradient(180deg, #fff3 0%, #fff0 100%), #fff3",
					}}
				>
					<div className="grid grid-cols-1">
						<div className="flex flex-col text-center">
							<h1 className="font-semibold">
								My USER ID Is: {userId}
							</h1>
						</div>
					</div>

					<div className="my-8 w-full border-b-2 border-[#C2EFF3]"></div>

					<div className="flex flex-col md:flex-row gap-6 w-full max-w-4xl mx-auto">
						<button
							onClick={handleRefreshClick}
							className="flex-1 bg-[#0A1A44] text-[#02B1BD] p-3 uppercase font-semibold rounded-2xl flex flex-row justify-center gap-3 items-center"
							disabled={userId ? false : true}
						>
							Refresh Ad
							<img
								src="/icons/add.svg"
								alt="icon"
								className="h-6"
							/>
						</button>
					</div>
				</div>
				{/* {!hideIframe && (
					<div className="flex flex-col my-10 justify-center items-center max-w-5xl w-full mx-auto">
						{refreshLoader ? (
							<ReactLoading
								type={"spin"}
								color={"#333"}
								height={25}
								width={25}
								style={{
									textAlign: "center",
									margin: "0 auto",
									width: "30px",
									height: "30px",
								}}
							/>
						) : (
							<TestAdsterra userId={userId} width={width} />
						)}
					</div>
				)} */}
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							A Comprehensive Guide to IP in General
						</div>
						<img
							src="/images/homepage/home-page-img-1.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							If you've been using the Internet for a while,
							chances are you've heard of IPs or IP addresses. But
							what exactly are they? How do they work, and why are
							they important? In this comprehensive guide, we will
							explain everything you need to know about IPs, from
							their definition and components to how to check and
							change your IP address.
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-1.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
			</section>
			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-2.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							What is an IP Address?
						</div>
						<img
							src="/images/homepage/home-page-img-2.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							An IP address is a unique numerical identifier
							assigned to each device connected to the Internet.
							Every time data is sent between two devices on the
							Internet; the sender includes its own IP address so
							that the recipient knows where it came from.
							<br />
							<br />
							For example, when you visit a website, your computer
							sends out a request for information with its own IP
							address attached. The server receives this request
							along with your computer's IP address, processes the
							request, and then sends back the requested
							information.
						</div>
					</div>
				</div>
			</section>
			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							How Does an IP Address Work?
						</div>
						<img
							src="/images/homepage/home-page-img-3.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							An IP address consists of four numbers separated by
							periods (for example, 199.121.232.11). Each number
							represents 8 bits of data and can range from 0-255.
							This means that there can be up to 4 billion
							possible combinations of numbers which allows for
							billions of computers across the world to have
							unique identifiers when communicating online.
							<br />
							<br />
							The other type of commonly used IP address is IPv6
							addresses which consist of 8 sets of 4 hexadecimal
							characters (for example, ABCD: EF12:3456:7890). The
							total number of IPv6 combinations is much larger
							than IPv4, allowing for virtually limitless
							possibilities for future expansion and growth on the
							Internet..
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-3.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
			</section>
			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-4.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							How to Check & Change Your IP Address?
						</div>
						<img
							src="/images/homepage/home-page-img-4.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							You can easily check what your current public-facing
							IP address is by visiting websites such as ipify or
							whatsmyipaddress, which will display your current
							internet protocol address in just a few seconds. You
							can also use these tools to check if your ISP has
							assigned you a dynamic or static IP address which
							affects how often it changes over time as well as
							how easy it would be for someone else to trace back
							activity on your connection to you specifically.
							<br />
							<br />
							Changing your IP address usually requires contacting
							your ISP and setting up a different physical
							connection, but there are also services such as VPNs
							that can allow you to "hide" behind another IP by
							routing all traffic through their servers instead,
							thus making it more difficult for anyone attempting
							to trace any activity back to you directly.
						</div>
					</div>
				</div>
			</section>
			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							The Different Types of IP Addresses
						</div>
						<img
							src="/images/homepage/home-page-img-5.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							There are multiple types of IP addresses that are
							used on the Internet, each with its own set of
							features and functions. The most commonly used IP
							address is IPv4, which has 32 bits and allows for up
							to 4 billion unique devices to be addressed.
							<br />
							<br />
							However, IPv6 has become increasingly popular over
							the last decade, as it offers 128 bits of address
							space - enough room for millions more unique
							addresses than IPv4. Additionally, there are two
							types of private IP addresses - those assigned by a
							network administrator (e.g., an internal intranet)
							and those that are self-assigned via DHCP (dynamic
							host configuration protocol).
							<br />
							<br />
							Finally, public/shared IP addresses are used by
							internet service providers and allow customers to
							share one single address for accessing the web.
							Understanding what purpose each type of address
							serves is essential in the making sure your online
							presence remains secure and functional.
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-5.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
			</section>
			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-6.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							What to do if You Think Someone is Using Your IP
							Address Without Permission
						</div>
						<img
							src="/images/homepage/home-page-img-6.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							If you think that someone is illegally using your IP
							address, the best thing to do is report it to law
							enforcement. It is important to have all the
							evidence you can provide and a clear record of the
							unauthorized use of your IP address.
							<br />
							<br />
							Document all of the instances where you believe
							someone was accessing your address in as much detail
							as possible, and then contact either your local
							police department or a cybercrime division if you
							need further assistance.
							<br />
							<br />
							If an investigation is needed, it would be
							beneficial to find other witnesses or evidence
							regarding the illegal usage and provide them with
							relevant information about what happened. The
							quicker you act on this situation, the more chances
							of catching the perpetrator before they cause more
							harm with your IP address.
						</div>
					</div>
				</div>
			</section>
			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							How to Hide Your IP Address
						</div>
						<img
							src="/images/homepage/home-page-img-7.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							With the ever-growing threats of cybercrime, it
							becomes more important than ever to take extra steps
							to ensure digital privacy and security. One way to
							protect yourself online is to hide your IP address.
							An IP address is basically like your personal
							digital fingerprint that identifies you on the
							Internet.
							<br />
							<br />
							Hiding your IP address can prevent someone from
							snooping around on your activity, including
							monitoring which websites you visit or what
							information you share. There are a few simple
							methods for hiding an IP address. Using a VPN
							(Virtual Private Network) service is a secure and
							reliable way to browse the Internet anonymously, as
							it encrypts internet traffic and masks the user's
							location by redirecting them through multiple
							servers.
							<br />
							<br />
							Proxies are another option, but their effectiveness
							varies depending on the type of proxy used and the
							security settings. Ultimately, taking extra
							precautions, such as masking your IP, helps keep
							your private information safe in today's digital
							age.
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-7.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
			</section>
			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/homepage/home-page-img-8.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							The Benefits of Using a VPN with an IP Address
							Changer
						</div>
						<img
							src="/images/homepage/home-page-img-8.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							Staying secure and anonymous online has become
							increasingly important for both personal and
							business use. Using a Virtual Private Network (VPN)
							with an IP address changer can offer multiple
							benefits to any user. By encrypting data across the
							web, a VPN provides a layer of security from
							malicious players and increased privacy from ISPs,
							marketers, and other third parties.
							<br />
							<br />
							Additionally, by changing the IP address, users can
							access geo-restricted content and bypass censorship
							restrictions within certain countries. VPNs allow
							users to connect to remote servers and experience
							the advantages of fast speeds, flexible protocols,
							strong encryption algorithms, no logging policies,
							and zero bandwidth caps or data limits. With so many
							options available in such an ever-evolving
							landscape, users can benefit greatly by utilizing a
							reputable VPN with an IP address changer.
						</div>
					</div>
				</div>
			</section>
			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							How to Choose the Right VPN for Your Needs
						</div>
						<img
							src="/images/homepage/home-page-img-9.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div>
							Choosing the right virtual private network (VPN) for
							your needs can be overwhelming, with the number of
							providers and features available to consider.
							However, it is important to do your research before
							making a decision.
							<br />
							<br />
							Start by assessing your needs: consider the type of
							protection you require, the countries you need
							access to, any specific requirements such as support
							for gaming or torrenting, and whether there are any
							time constraints on data limits or bandwidth.
							<br />
							<br />
							Once you have identified your requirements, compare
							different providers and read online reviews before
							deciding which one best suits your needs. Do not be
							afraid to reach out and ask questions directly to
							customer support if needed; having all the answers
							at hand will make it much easier to select the right
							VPN provider for you.
						</div>
					</div>
					<img
						src="/images/homepage/home-page-img-9.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
			</section>
			<section className="px-3 py-20 bg-white md:hidden">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							Conclusion:
						</div>
						<div>
							IP addresses play an essential role in ensuring
							secure communication over the Internet by providing
							unique identifiers that allow us to distinguish one
							device from another when sending data across
							networks or between systems online.
							<br />
							<br />
							Knowing what an IP address is, how it works and how
							we can check/change our own IP addresses helps us
							stay safe online while still enjoying all the
							benefits that come with using the web today!.
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default Test;
