import {
    GET_IP_LOOKUP_FAIL, GET_IPV6_SUCCESS,
    GET_IPV6_FAILED, GET_IP_LOOKUP_SUCCESS,
    GET_IP_LOOKUP_REQUEST, GET_OWN_IP_SUCCESS,
    UPDATE_DETAILS_IP_INFO, SAVE_MY_IP_DETAILS,
    SAVE_MY_IP_DETAILS_FAIL, SAVE_MY_IP_DETAILS_SUCCESS,
    SAVE_MY_IP_DETAILS_REQUEST,
    SET_PRECISE_LOCATION,
    GET_USER_IPINFO_SUCCESS,
    SAVE_USER_IPINFO_REQUEST,
    SAVE_USER_IPINFO_SUCCESS,
    SAVE_USER_IPINFO_FAILED,
    SET_LOCATION_PERMISSION_STATUS,
    CLEAR_FIND_BY_IP_DETAILS, 
} from "../types";

const INITIAL_STATE = {
    ipLookupLoader: false,
    ipLookupData: {},
    ownIpAddress: {},
    ipLookupError: '',
    internetProviderDetails: {},
    IPVersion6: '',
    userIPInfoHistoryData:[],
    saveHistoryLoader:false,
    locatinPermissionStatus:false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_IP_LOOKUP_REQUEST:
            return { ...state, ipLookupLoader: true, ipLookupData: {}, ipLookupError: "" };
        case GET_IP_LOOKUP_SUCCESS:
            return { ...state, ipLookupLoader: false, ipLookupData: action.payload };
        case GET_IP_LOOKUP_FAIL:
            return { ...state, ipLookupLoader: false, ipLookupError: action.payload, ipLookupData: {} };
        case GET_OWN_IP_SUCCESS:
            return { ...state, ownIpAddress: action.payload };
        case UPDATE_DETAILS_IP_INFO:
            return { ...state, findIpDetail: action.payload };
        case SAVE_MY_IP_DETAILS_REQUEST:
            return { ...state, internetProviderDetails: {} }
        case SAVE_MY_IP_DETAILS_SUCCESS:
            localStorage.setItem("MyIpDetail", JSON.stringify(action.payload))
            return { ...state, internetProviderDetails: action.payload }
        case SAVE_MY_IP_DETAILS_FAIL:
            return { ...state, internetProviderDetails: {} }
        case GET_IPV6_SUCCESS:
            return { ...state, IPVersion6: action.payload }
        case GET_IPV6_FAILED:
            return { ...state, IPVersion6: 'Not Detected!' }
        case SET_PRECISE_LOCATION:
                return { ...state, ipLookupData: action.payload,locatinPermissionStatus:true }
        case GET_USER_IPINFO_SUCCESS:
                return { ...state, userIPInfoHistoryData: action.payload }
        case SAVE_USER_IPINFO_REQUEST:
            return { ...state, saveHistoryLoader: true }
        case SAVE_USER_IPINFO_SUCCESS:
            return { ...state, saveHistoryLoader: false }
        case SAVE_USER_IPINFO_FAILED:
            return { ...state, saveHistoryLoader: false }
        case SET_LOCATION_PERMISSION_STATUS:
            return { ...state, locatinPermissionStatus: action.payload }
        case CLEAR_FIND_BY_IP_DETAILS:
            return { ...state, findIpDetail: '' };
        default:
            return state;
    }
} 