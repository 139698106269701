// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-box{
    background-color: #d9d9d9;
    padding: 40px;
    border-radius: 10px;
}
.ip-box-label {
    font-weight: 500;
}
.ip-box-wrap {
    display: flex;
    margin-bottom: 30px;
    font-size: 16px;
}
.no-data p{
    font-size: 20px;
    color: #000;
    font-weight: 500;
    text-align: center;
}
.ip-box-wrap .ip-box-label{
   
    font-weight: 500;
}
.ip-box-wrap:last-child{
    margin-bottom: 0;
}
.ipdetail.container{
    max-width: 940px;
}
.map-image img {
    width: 100%;
    border-radius: 20px;
    border: 4px solid #fff;
}
.add-image img{
    width: 100%;
}
.ip-box-text{
    color: #6c6b6b;
    margin-left: 5px;
}
.ip-box-wrap-latitude .ip-box-wrap {
    margin-bottom: 0;
    margin-top: 10px;
}
.map_address_wrap_full div {
    border-radius: 20px !important;
}

@media (max-width: 991px){
.ip-box {
    padding: 20px;
}
.ip-box-wrap {
    margin-bottom: 10px;
    font-size: 12px;
}
.add-image {
    display: none;
}
.map-image {
    margin-top: 20px;
}
.ip-box {
    background-color: #f4f4f4;
    border: 3px solid #0e96fb;
}
}`, "",{"version":3,"sources":["webpack://./src/views/IpDetails/IpDetails.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;AACA;;IAEI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,8BAA8B;AAClC;;AAEA;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;AACA","sourcesContent":[".ip-box{\n    background-color: #d9d9d9;\n    padding: 40px;\n    border-radius: 10px;\n}\n.ip-box-label {\n    font-weight: 500;\n}\n.ip-box-wrap {\n    display: flex;\n    margin-bottom: 30px;\n    font-size: 16px;\n}\n.no-data p{\n    font-size: 20px;\n    color: #000;\n    font-weight: 500;\n    text-align: center;\n}\n.ip-box-wrap .ip-box-label{\n   \n    font-weight: 500;\n}\n.ip-box-wrap:last-child{\n    margin-bottom: 0;\n}\n.ipdetail.container{\n    max-width: 940px;\n}\n.map-image img {\n    width: 100%;\n    border-radius: 20px;\n    border: 4px solid #fff;\n}\n.add-image img{\n    width: 100%;\n}\n.ip-box-text{\n    color: #6c6b6b;\n    margin-left: 5px;\n}\n.ip-box-wrap-latitude .ip-box-wrap {\n    margin-bottom: 0;\n    margin-top: 10px;\n}\n.map_address_wrap_full div {\n    border-radius: 20px !important;\n}\n\n@media (max-width: 991px){\n.ip-box {\n    padding: 20px;\n}\n.ip-box-wrap {\n    margin-bottom: 10px;\n    font-size: 12px;\n}\n.add-image {\n    display: none;\n}\n.map-image {\n    margin-top: 20px;\n}\n.ip-box {\n    background-color: #f4f4f4;\n    border: 3px solid #0e96fb;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
