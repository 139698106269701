import {
    GET_USER_FOUND,
    GET_USER_REQUEST,
    GET_USER_NOT_FOUND,
    LOGOUT_SUCCESS
} from "../types";

const INITIAL_STATE = { 
    loading: false,
    user: {},
    token: '',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }; 
        case GET_USER_FOUND:
                return { 
                    ...state, 
                    user: action.payload,
                    loading: false
                };
        case GET_USER_NOT_FOUND:
                return { ...INITIAL_STATE };    
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            return {...state, user: {},token:'' };
        default:
            return state;
    }
}