import axios from "axios";
import {
    GET_AGENT_FAIL, GET_AGENT_SUCCESS, GET_AGENT_REQUEST,
    GET_USER_AGENT_LOOKUP_REQUEST,
    GET_USER_AGENT_LOOKUP_SUCCESS,
    GET_USER_AGENT_LOOKUP_FAIL,
    GET_SYSTEM_INFO_SUCCESS,
    GET_SYSTEM_INFO_FAILED,
    GET_SYSTEM_INFO_REQUEST,
    GET_EXTERNAL_LOGGER_SUCCESS,
    GET_EXTERNAL_LOGGER_FAILED
} from "../types";
import { BASE_URL } from "../config";
//import platform from "platform";


export const GetUserAgentData = (payloadData, callback = () => { }) => {
    return async (dispatch) => {
        dispatch({ type: GET_AGENT_REQUEST });
        axios.post(`${BASE_URL}ip-lookup`, payloadData)
            .then(res => {
                if (res.data)

                    dispatch({ type: GET_AGENT_SUCCESS, payload: res.data });
                callback(res.data)
            })
            .catch(err => {
                dispatch({ type: GET_AGENT_FAIL });
                //CustomException(err)
            })
    }
}

export const GetUserAgentLookup = (useragent) => (dispatch) => {
    const payload = { useragent: useragent };
    dispatch({ type: GET_USER_AGENT_LOOKUP_REQUEST });

    axios.post(`${BASE_URL}user_agent-lookup`, payload)
        .then((response) => {
            return dispatch({ type: GET_USER_AGENT_LOOKUP_SUCCESS, payload: response.data })
        })
        .catch((error) => {
            return dispatch({ type: GET_USER_AGENT_LOOKUP_FAIL, payload: error })
        })
}

export const getSystemInfoAction = () => (dispatch) => {
    dispatch({ type: GET_SYSTEM_INFO_REQUEST });

    let realAgentText; 
    try{
        navigator.userAgentData.getHighEntropyValues(['platform', 'platformVersion', 'architecture', 'model', 'uaFullVersion'])
        .then((ua) => {   
            realAgentText = `Platform: ${ua?.platform} / Platform Version: ${ua?.platformVersion} / Architecture: ${ua?.architecture} / Model: ${ua?.model?ua.model:`Not Found`} / UA Full Version: ${ua?.uaFullVersion} `;   
            return dispatch({ type: GET_SYSTEM_INFO_SUCCESS, payload: realAgentText })
        })
    }catch(e){
        if(navigator?.userAgent){
            const userAgent = navigator.userAgent; 
            let platform = 'Unknown';
            let platformVersion = 'Unknown';
            let architecture = 'Unknown';
            let uaShortVersion = 'Unknown';
          
            if (/Macintosh/.test(userAgent)) {
              platform = 'macOS';
              const macVersionMatch = userAgent.match(/Mac OS X (\d+_\d+(_\d+)?)/);
              if (macVersionMatch) {
                platformVersion = macVersionMatch[1].replace(/_/g, '.');
              }
              architecture = /Intel/.test(userAgent) ? 'x86_64' : 'arm64';  // Educated guess based on known patterns
              uaShortVersion = userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || 'Unknown';
            } 
            realAgentText = `Platform: ${platform} / Platform Version: ${platformVersion} / Architecture: ${architecture} / Model: Not Found / UA Full Version: ${uaShortVersion} `;   
            return dispatch({ type: GET_SYSTEM_INFO_SUCCESS, payload: realAgentText })
        }else{
            return dispatch({ type: GET_SYSTEM_INFO_FAILED, payload: '' })
        }
    } 
}

/*export const getSystemInfoActionOld = () => (dispatch) => {
    dispatch({ type: GET_SYSTEM_INFO_REQUEST });
    axios.get(`${BASE_URL}get-system-info`)
        .then((response) => {
            let realAgentRes = response?.data;
            //let realAgentText = `${platform?.os} / ${realAgentRes?.system?.uuid} / ${realAgentRes?.osInfo?.hostname} / ${realAgentRes?.osInfo?.platform}-${realAgentRes?.osInfo?.release} / ${realAgentRes?.cpu?.manufacturer}-${realAgentRes?.cpu?.brand} / ${realAgentRes?.system?.manufacturer}`;
            let realAgentText = `${platform?.description} /  ${realAgentRes?.osInfo?.hostname} / ${realAgentRes?.system?.manufacturer}`;
            return dispatch({ type: GET_SYSTEM_INFO_SUCCESS, payload: realAgentText })
        })
        .catch((error) => {
            return dispatch({ type: GET_SYSTEM_INFO_FAILED, payload: '' })
        })
}*/

export const getExternalLogger = () => (dispatch) => {
    axios.get("https://event.myweb3id.io/reverse_events")
        .then((response) => {
            let oldData = response.data; 
            let latestData = oldData.filter(obj => Object.keys(obj).length > 1);              
            return dispatch({ type: GET_EXTERNAL_LOGGER_SUCCESS, payload: latestData })
        })
        .catch((error) => { return dispatch({ type: GET_EXTERNAL_LOGGER_FAILED, payload: error.data }) })
}



