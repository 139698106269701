import "./App.css";
import Routes from "./Routes";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import AuthProvider from "./AuthProvider";
import { PersistGate } from "redux-persist/integration/react";
import { useEffect } from "react";

function App() {
  /*START: To remove extra space on scrollup when ADs shown */
  window.addEventListener("scroll", () => {
    if (window.scrollY === 0) {
      let bodyEl = document.getElementsByTagName("body")[0];
      if (document.querySelector(`[data-anchor-status="displayed"]`)) {
        if (bodyEl.style?.paddingBottom === "0px") {
          bodyEl.style.padding = "92px 0px 0px;";
          bodyEl.style.paddingTop = "92px";
        }
      }
      if (document.querySelector(`[data-anchor-status="dismissed"]`)) {
        if (bodyEl.style?.paddingBottom === "0px") {
          bodyEl.style.padding = "30px 0px 0px;";
          bodyEl.style.paddingTop = "30px";
        }
      }
    }
  });
  window.addEventListener("click", (e) => {
    if (
      e.target?.className === "grippy-host" &&
      e.target?.parentElement?.dataset?.anchorStatus
    ) {
      let bodyEl = document.getElementsByTagName("body")[0];
      if (e.target?.parentElement?.dataset?.anchorStatus === "dismissed") {
        if (bodyEl.style?.paddingBottom === "0px") {
          bodyEl.style.padding = "92px 0px 0px;";
          bodyEl.style.paddingTop = "92px";
        }
      }
      if (e.target?.parentElement?.dataset?.anchorStatus === "displayed") {
        if (bodyEl.style?.paddingBottom === "0px") {
          bodyEl.style.padding = "30px 0px 0px;";
          bodyEl.style.paddingTop = "30px";
        }
      }
    }
  });
  /*END: To remove extra space on scrollup when ADs shown */

  /** START: Cookiebot - YD-34 -  GDPR Popup for all pages*/
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consent.cookiebot.com/uc.js";
    script.id = "Cookiebot";
    script.dataset.cbid = "d3f9d706-fc2b-4b89-88eb-c97b354963bc";
    script.dataset.blockingmode = "auto";
    script.type = "text/javascript";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  /** END: Cookiebot - YD-34 -  GDPR Popup for all pages*/

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
