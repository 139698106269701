// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#speedchecker_logo{
    display: none !important;
}

.LoggerTable th {
    border-bottom: 2px solid #ccc;
    padding: 0 5px 10px;
    font-size: 14px;
}

.LoggerTable td {
    border-bottom: 1px solid #ccc;
    padding: 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');\n\n#speedchecker_logo{\n    display: none !important;\n}\n\n.LoggerTable th {\n    border-bottom: 2px solid #ccc;\n    padding: 0 5px 10px;\n    font-size: 14px;\n}\n\n.LoggerTable td {\n    border-bottom: 1px solid #ccc;\n    padding: 0 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
