import React, { useEffect, useState } from "react";
import Menubar from "../../components/Menubar/Menubar";
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { GetUserAgentLookup } from '../../actions/userAgentAction';
import ReactLoading from "react-loading";

//css
import "./UserAgent.css";

// image
import result from "../../images/result.png";
import blog1 from "../../images/user-agent-page-img-1.png";
import blog2 from "../../images/user-agent-page-img-2.jpg";
import blog3 from "../../images/user-agent-page-img-3.png";
import blog4 from "../../images/user-agent-page-img-4.png";
import blog5 from "../../images/user-agent-page-img-5.png";
import blog6 from "../../images/user-agent-page-img-6.png";
import blog7 from "../../images/user-agent-page-img-7.png";
import blog8 from "../../images/user-agent-page-img-8.png";
import blog9 from "../../images/user-agent-page-img-9.png";
import useragent from "../../images/user-agent.png";
import add from "../../images/add.jpg";

const UserAgent = () => {
  const dispatch = useDispatch();
  const { lookupAgentData, lookupAgentLoader } = useSelector((state) => state.userAgentReducer);
  const [Error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [showAgentLookupTable, setshowAgentLookupTable] = useState(false);
  const [agentData, setAgentData] = useState({
    useragent: window.navigator.userAgent,
    platform: window.navigator.platform,
    browser: '',
    screenSizeWidth: window.screen.availWidth,
    screenSizeHeight: window.screen.availHeight,
    cookie: window.navigator.cookieEnabled ? 'Enabled' : 'Disabled',
    javascript: window.navigator.javaEnabled ? 'Enabled' : 'Disabled',

  })
  const [UserAgentString, setUserAgentString] = useState('');

  const getBrowserName = () => {
    if (agentData?.useragent?.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (agentData?.useragent?.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (agentData?.useragent?.includes("Opera") || agentData?.useragent?.includes("OPR")) {
      return "Opera";
    } else if (agentData?.useragent?.includes("Trident")) {
      return "Microsoft Internet Explorer";
    } else if (agentData?.useragent?.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (agentData?.useragent?.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (agentData?.useragent?.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (agentData?.useragent?.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }

  }
  const submitHandler = () => {
    if (!UserAgentString) {
      setShowError(true);
      setError(" Please enter a valid String...!")
      setshowAgentLookupTable(false);
    } else {
      dispatch(GetUserAgentLookup(UserAgentString));
      setshowAgentLookupTable(true);
    }
  }
  useEffect(() => {
    const timeout = setTimeout(() => { setCopied(false); }, 3000);
    return () => clearTimeout(timeout);
  }, [isCopied]);
  return (
    <div>
      <Menubar />
      <Container className="mb-4">
        <Row>
          <Col lg={12} className="text-center">
            <div className="text-center useragent">
              <img src={useragent} alt="" />
              <h3> USER AGENT</h3>
              <p>
                Use the user agent lookup tool above to see what your user agent
                <br />
                presents as to the websites you visit within your browsing
                session.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4">
        <Row>
          <Col lg={12}>
            <div>
              <div className="agentlook agentlook-title">
                <h4 className="text-center">Your User Agent is:</h4>
                <p>{agentData?.useragent}
                  <span style={{ display: "inline-block", marginLeft: "10px" }}>
                    <div className="ip_address_icon" >
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-top`} style={{ width: "150px", textAlign: "center" }}>
                            {isCopied ? "Copied!" : "Copy to clipboard"}
                          </Tooltip>
                        }
                      >
                        <img
                          src={result}
                          style={{ height: '25px' }}
                          alt=""
                          onClick={() => {
                            navigator.clipboard.writeText(agentData?.useragent);
                            setCopied(true);
                          }} />
                      </OverlayTrigger>
                    </div>
                  </span>
                </p>
                <div className="platform">
                  <div className="platform-wrap">
                    <div className="platform-label">platform</div>
                    <div className="platform-text">{agentData?.platform}</div>
                  </div>
                  <div className="platform-wrap">
                    <div className="platform-label">browser</div>
                    <div className="platform-text">{getBrowserName()}</div>
                  </div>
                  <div className="platform-wrap">
                    <div className="platform-label">Screen Size</div>
                    <div className="platform-text">{agentData?.screenSizeWidth} * {agentData?.screenSizeHeight}</div>
                  </div>
                  <div className="platform-wrap">
                    <div className="platform-label">Cookie</div>
                    <div className="platform-text">{agentData?.cookie}</div>
                  </div>
                  <div className="platform-wrap">
                    <div className="platform-label">Javascript</div>
                    <div className="platform-text">{agentData?.javascript}</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4">
        <Row>
          <Col lg={12} className="text-center">
            <div className="agentlook-title">
              <h4>User Agent Lookup</h4>
              <p className="mb-4">
                Do you have a User Agent string that you want to lookup? The
                tool below will parse
                <br /> the User String, and provide you with the platform,
                operating system, and browser.
              </p>
              <div className="go mt-4">
                <Form className="go-form">
                  <Form.Group className="go-form-wrap" controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="email" placeholder="Enter a user-agent string"
                      onChange={(e) => {
                        setUserAgentString(e.target.value);
                        setShowError(false);
                      }} />
                    <Button
                      onClick={() => submitHandler()}
                      variant="primary">Go</Button>
                  </Form.Group>
                </Form>
              </div>
              {showError ? <h4 className="error-message" style={{ textAlign: "center", color: "red" }}>{Error}</h4> : ""}
              {showAgentLookupTable ? <Container className="mb-4">
                <Row>
                  <Col lg={12}>
                    <div>
                      <div className="agentlook agentlook-title">
                        <h4 className="text-center">User Agent Lookup:</h4>
                        {lookupAgentLoader ? <div className="loading__icon">
                          <ReactLoading type={"spin"} color={"#333"} height={25} width={25} style={{ textAlign: 'center', margin: '0 auto', width: '30px', height: '30px' }} />
                        </div> :
                          <div className="platform">
                            <div className="platform-wrap">
                              <div className="platform-label">User Agent</div>
                              <div className="platform-text">{lookupAgentData?.user_agent}
                                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                                  <div className="ip_address_icon" >
                                    <OverlayTrigger
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-top`} style={{ width: "150px", textAlign: "center" }}>
                                          {isCopied ? "Copied!" : "Copy to clipboard"}
                                        </Tooltip>
                                      }
                                    >
                                      <img
                                        src={result}
                                        style={{ height: '25px' }}
                                        alt=""
                                        onClick={() => {
                                          navigator.clipboard.writeText(lookupAgentData?.user_agent);
                                          setCopied(true);
                                        }} />
                                    </OverlayTrigger>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div className="platform-wrap">
                              <div className="platform-label">platform</div>
                              <div className="platform-text">{lookupAgentData?.platform}</div>
                            </div>
                            <div className="platform-wrap">
                              <div className="platform-label">browser</div>
                              <div className="platform-text">{lookupAgentData?.browser}</div>
                            </div>
                            <div className="platform-wrap">
                              <div className="platform-label">Version</div>
                              <div className="platform-text">{lookupAgentData?.version}</div>
                            </div>
                            <div className="platform-wrap">
                              <div className="platform-label">Operating System</div>
                              <div className="platform-text">{lookupAgentData?.operating_system}</div>
                            </div>
                            <div className="platform-wrap">
                              <div className="platform-label">Screen Size</div>
                              <div className="platform-text">{lookupAgentData?.screen_size}</div>
                            </div>
                          </div>}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container> : ""}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4 addbanner mt-4">
        <Row>
          <Col lg={12}>
            <img src={add} alt="" />
          </Col>
        </Row>
      </Container>
      {/* SEO BLOGS */}
      <div className="seo-section">
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog1} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>What is a User Agent and Why Should Professionals Care?</h2>
                  <p>The term "User-Agent" is one you may not have heard before. It has everything to do with how users access the internet and browse websites, but it also has implications for professional web developers and designers.</p>
                  <p>Let's take a closer look at what a user agent is and why professionals should care. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog2} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>What is a User Agent?</h2>
                  <p>A user agent (UA) is a software program that allows users to access the internet, including websites, applications, search engines, and more. It allows users to interact with their digital environment by providing information about the device they are using and its capabilities. </p>
                  <p>Examples of user agents include browsers like Chrome or Firefox; email programs such as Outlook or Gmail; operating systems like Windows, Mac OS, iOS, or Android; bots that collect data for search engine optimization (SEO); and desktop applications such as Adobe Creative Suite.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog3} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How Can Professionals Benefit from Understanding User Agents and Their Capabilities? </h2>
                  <p>A thorough understanding of user agents and their capabilities can provide a great benefit to professionals. When working with content delivery networks, analytics engines, or web browsers, having the knowledge to develop content in accordance with the specific needs, rules, and available features of different user agents helps to ensure that each individual agent's experience is optimized.</p>
                  <p>For instance, recognizing what devices or browsers can support media extensions can help to create more interactive content while understanding the limitations of some web crawlers can better inform how websites are structured for events such as indexing.</p>
                  <p>Moreover, professionals who have an understanding of user agents have an advantage when it comes to building accessible interfaces; not only do they know what technical specifications need to be met for successful execution, but they are also more aware of how people interact with different types of agents and therefore can craft even more inclusive applications.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog4} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>What are Some of the Most Popular User Agents on the Market Today, and Why are they So Popular?</h2>
                  <p>Today, user agents have become an essential part of the internet landscape. Perhaps the most popular user agent is Google Chrome, a web browser created by Google that runs on desktop and mobile devices. It is popular due to its streamlined and user-friendly interface, as well as its support for rich media content.</p>
                  <p>Additionally, Chrome has integrated features such as history sync and autofill that make it very attractive for users who want to access their data from multiple devices simultaneously. Other popular user agents include Apple Safari, Mozilla Firefox, Microsoft Internet Explorer, and Microsoft Edge. These are also popular because of their ability to provide comprehensive browsing experiences for both basic and complex tasks.</p>
                  <p>Each offers great security features along with customizable preferences, allowing users to control their own experience within these browsers.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog5} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>How Can Professionals Make Sure that their Website or Application is Optimized for Different User Agents and Devices?</h2>
                  <p>Ensuring that a website or application is optimized for different user agents and devices is critical for all professionals looking to create digital products. There are several techniques that can be employed to make sure the user experience of each visitor is top-notch, no matter which device they are using or what software agent they have installed.</p>
                  <p>First and foremost, webmasters should use tools like the Google Search Console to provide feedback on how the website is performing, then take action based on the insights it provides. Similarly, developers need to invest time in mobile optimization strategies, including testing their site with various screen resolutions and network speeds.</p>
                  <p>Finally, all website owners should consider implementing hosted chat services so as to communicate better with their end users. By following these steps, professionals can be assured they have made all necessary efforts to ensure their websites and applications are optimized for any user and device - ultimately leading to a better overall experience.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog6} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Are There Any Risks Associated with Using Certain User Agents, and How Can These be Avoided or Mitigated?</h2>
                  <p>Although user agents can be a great way to quickly access digital information, there are potential risks associated with their use. As user agents often store and make use of personal or sensitive data, bad actors may try to exploit vulnerabilities in the user agent. To help mitigate this risk, users should always ensure that their user agents are up-to-date and avoid using unofficial or outdated versions that may contain security flaws.</p>
                  <p>Additionally, it is important to be aware of which websites are attempting to gain access to an individual's browsing activity through the use of user agents, as malicious actors may also be attempting to track users' online activity without their knowledge. By carefully managing their user agent usage and staying aware of security risks, individuals can greatly reduce the chances of compromising their own privacy or safety when using this technology.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog7} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>What Are Some Best Practices for Working with User Agents in a Professional Setting?</h2>
                  <p>Working with user agents in a professional setting requires the utmost respect and etiquette. It is important to remember that user agents may have different levels of technical knowledge than you and to communicate accordingly.</p>
                  <p>First, always take their problems seriously, listen intently, and allow them to explain their concerns as much as needed until your mutual understanding is reached. Additionally, try not to pressure them into decisions they are not ready for, always provide honest feedback about the capacities of your system and acknowledge that it might not meet all of their needs.</p>
                  <p>Furthermore, show appreciation for their service when you can and strive to resolve any issues within the timeframe promised. Finally, keep good records in case a problem arises after you've worked together. Following these best practices will help build long-term working relationships with user agents while ensuring satisfaction on both sides.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5} className="order-lg-2">
                <div className="seo-section-image">
                  <img src={blog8} alt="" />
                </div>
              </Col>
              <Col lg={5} className="order-lg-1">
                <div className="seo-section-content">
                  <h2>Why Should Professionals Care About User Agents? </h2>
                  <p>User agents are an important part of website optimization because they allow developers to understand who their visitors are and how they access the content. Knowing this information can help with decisions like making sure that the website works properly on all devices (i.e., mobile phones versus laptops).</p>
                  <p>It can also be helpful in understanding how people navigate your website so that you can make changes accordingly. For example, if you notice that your visitors tend to click on certain links more than others, you might want to move those links higher up on the page for easier access.</p>
                  <p>Additionally, user agents can be used to detect malicious activity or suspicious behavior so that your site remains secure from hackers and other cybercriminals.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="seo-section-inner">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <div className="seo-section-image">
                  <img src={blog9} alt="" />
                </div>
              </Col>
              <Col lg={5}>
                <div className="seo-section-content">
                  <h2>Conclusion</h2>
                  <p>User agents are an essential part of any professional web designer or developer's toolkit because they provide valuable insights into their customers' behaviors online. They allow developers to understand who their visitors are, how they access content, and what kind of device they use so that websites can be optimized for maximum performance across multiple platforms.</p>
                  <p>With user agents playing such an important role in website security and optimization, having an understanding of them is key for any professional looking to stay ahead of the game when it comes to creating successful web designs!</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div >
  );
};

export default UserAgent;
