export const checkGeolocationPermission =()=> {
    return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
            reject('Geolocation is not supported by this browser.');
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve('granted');
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        resolve('denied');
                    } else {
                        resolve('prompt');
                    }
                }
            );
        }
    });
}