import React, { Fragment, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./views/Home/Home";
import HomeOld from "./views/Home/HomeOld";
///import IpDetails from "./views/IpDetails/IpDetails";
import IpLookUp from "./views/IpLookUp/IpLookUp";
import IpLookUpOld from "./views/IpLookUp/IpLookUpOld";
import PrivacyPolicy from "./views/PrivacyPolicy";
import SpeedTest from "./views/SpeedTest/SpeedTest";
import SpeedTestOld from "./views/SpeedTest/SpeedTestOld";
import TermsAndCondition from "./views/TermsAndCondition";
import UserAgent from "./views/UserAgent/UserAgent";
import UserAgentOld from "./views/UserAgent/UserAgentOld";
import TestHome from "./views/TestHome/TestHome";
import Redirect from "./components/Redirect";
import JsonToCSV from "./views/JsonToCSV/JsonToCSV";

const Routes = () => {
  return (
    <Suspense fallback={""}>
      <Router>
        <Fragment>
          <ScrollToTop />
          <div className="pt-[var(--header-height)]">
            <Switch>
              <Route exact path="/redirect" component={Redirect} />
              {/* OLD */}
              <Route exact path="/old" component={HomeOld} />
              <Route exact path="/old/speed-test" component={SpeedTestOld} />
              <Route exact path="/old/ip-lookup" component={IpLookUpOld} />
              <Route exact path="/old/user-agent" component={UserAgentOld} />

              <Route exact path="/" component={Home} />
              {/* <Route exact path="/ip-details" component={IpDetails} /> */}
              <Route exact path="/speed-test" component={SpeedTest} />
              <Route exact path="/ip-lookup" component={IpLookUp} />
              <Route exact path="/user-agent" component={UserAgent} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndCondition}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/test" component={TestHome} /> 
              <Route exact path="/json-export" component={JsonToCSV} />
            </Switch>
          </div>
        </Fragment>
      </Router>
    </Suspense>
  );
};

export default Routes;
