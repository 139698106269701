import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import MenuAdsterra from "../AdPlacement/MenuAdsterra";
import footerLogo from "../../images/myIP_Footer.png"

const Footer = () => {
	const { t } = useTranslation();
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		window.addEventListener("resize", handleResize);
		window
			.matchMedia("(orientation: portrait)")
			.addEventListener("change", (e) => {
				setWidth(window.innerWidth - (window.innerWidth * 20) / 100);
			});
		return () => window.removeEventListener("resize", handleResize);
	}, [width]);

	return (
		<>
			<footer className="px-3 bg-gradient-to-r from-[#243949] to-[#01B1BD]">
				{/* <div className="flex flex-col  justify-center items-center max-w-5xl w-full mx-auto">
					<MenuAdsterra width={width} layout={`footerMenu`} />
				</div> */}
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-10 w-full mx-auto max-w-5xl">
					<Link
						to="/"
						className="flex flex-col justify-center items-center"
					>
						<img src={footerLogo} alt="logo" className="w-[160px] h-auto" />
					</Link>
					<div className="grid grid-cols-2 gap-2 text-white">
						<Link className="hover:text-[#0a1a44]" to="/">
							{t('general.menu_my_ip')}
						</Link>
						<a
							className="hover:text-[#0a1a44]"
							href="mailto://contact.myipinfo@gmail.com"
						>
							{t('general.contact')}
						</a>
						{/* {<Link className="hover:text-[#0a1a44]" to="/ip-lookup">
            IP Lookup
          </Link>} */}
						<Link
							className="hover:text-[#0a1a44]"
							to="/terms-and-conditions"
						>
							{t('general.terms_condition')}
						</Link>
						<Link className="hover:text-[#0a1a44]" to="/user-agent">
							{t('general.menu_user_agent')}
						</Link>
						<Link
							className="hover:text-[#0a1a44]"
							to="/privacy-policy"
						>
							{t('general.privacy_policy')}
						</Link>
						<Link className="hover:text-[#0a1a44]" to="/speed-test">
							{t('general.menu_speed_test')}
						</Link>
					</div>
					<div className="flex flex-col items-center justify-center gap-1 text-white">
						<div>{t('general.contact_mail')}</div>
						<div>{t('general.copyright_text')}</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
