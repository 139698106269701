import React from "react";
import GoogleMapReact from "google-map-react";
import pin from "../../images/pin.png";
import "./Home.css";
import { GOOGLE_MAPS_KEY } from "../../config";

const markerStyle = {
  position: "absolute",
  width: "24px",
  height: "24px",
};

const MarkerIcon = () => (
  <div>
    <img style={markerStyle} src={pin} alt="pin" />
  </div>
);

export default function GoogleMaps({ lat, lng,key }) {
  const defaultProps = {
    center: { lat: lat, lng: lng },
    zoom: 11,
  };
  return (
    <div className="h-full w-full overflow-hidden">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        key={key}
      >
        <MarkerIcon lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
}
