import React, { useEffect, useState } from "react";
import Menubar from "../../components/Menubar/Menubar";
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	GetUserAgentLookup,
	getSystemInfoAction,
} from "../../actions/userAgentAction";
import ReactLoading from "react-loading";

//css
import "./UserAgent.css";

// image
import AdPlacement from "../../components/AdPlacement/AdPlacement";
import AdPlacementCustom from "../../components/AdPlacement/AdPlacementCustom";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-scroll";
import { UserAgentCopyAction, UserAgentLookupAction, UserAgentShowRealAction, UserAgentViewAction } from "../../actions/googleAnalyticsActions";
import { useTranslation } from "react-i18next";

const UserAgent = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const {
		lookupAgentData,
		lookupAgentLoader,
		readUserAgentSystemInfo,
		systemInfoLoader,
	} = useSelector((state) => state.userAgentReducer);
	const [Error, setError] = useState("");
	const [showError, setShowError] = useState(false);
	const [isCopied, setCopied] = useState(false);
	const [showAgentLookupTable, setshowAgentLookupTable] = useState(false);
	const [agentData, setAgentData] = useState({
		useragent: window.navigator.userAgent,
		platform: window.navigator.platform,
		browser: "",
		screenSizeWidth: window.screen.availWidth,
		screenSizeHeight: window.screen.availHeight,
		cookie: window.navigator.cookieEnabled ? t('general.enabled') : t('general.disabled'),
		javascript: window.navigator.javaEnabled ? t('general.enabled') : t('general.disabled'),
	});
	const [UserAgentString, setUserAgentString] = useState("");
	const [realUserAgentVisible, setRealUserAgentVisible] = useState(false);

	const getBrowserName = () => {
		if (agentData?.useragent?.includes("Firefox")) {
			return t('general.mozilla_firefox');
		} else if (agentData?.useragent?.includes("SamsungBrowser")) {
			return t('general.samsung_internet');
		} else if (
			agentData?.useragent?.includes("Opera") ||
			agentData?.useragent?.includes("OPR")
		) {
			return t('general.opera');
		} else if (agentData?.useragent?.includes("Trident")) {
			return t('general.microsoft_internet_explorer');
		} else if (agentData?.useragent?.includes("Edge")) {
			return t('general.microsoft_edge_legacy');
		} else if (agentData?.useragent?.includes("Edg")) {
			return  t('general.microsoft_edge_chromium');
		} else if (agentData?.useragent?.includes("Chrome")) {
			return  t('general.google_chrome_or_chromium');
		} else if (agentData?.useragent?.includes("Safari")) {
			return  t('general.apple_safari');
		} else {
			return t('general.unknown');
		}
	};
	const submitHandler = () => {
		if (!UserAgentString) {
			setShowError(true);
			setError(" Please enter a valid String...!");
			setshowAgentLookupTable(false);
		} else {
			dispatch(UserAgentLookupAction({}));
			dispatch(GetUserAgentLookup(UserAgentString));
			setshowAgentLookupTable(true);
		}
	};
	useEffect(() => {
		const timeout = setTimeout(() => {
			setCopied(false);
		}, 3000);
		return () => clearTimeout(timeout);
	}, [isCopied]);

	const handleShowRealAgent = async () => {
		
		if (readUserAgentSystemInfo) {
			setRealUserAgentVisible(!realUserAgentVisible);
		} else {
			dispatch(UserAgentShowRealAction({}))
			await dispatch(getSystemInfoAction());
			await setRealUserAgentVisible(!realUserAgentVisible);
		}
	};


	useEffect(() => {
		dispatch(UserAgentViewAction());
	}, []);

	let adsStyle = {
		display: "inline-block",
		width: "300px",
		height: "250px",
	};

	return (
		<div>
			<Menubar />

			<section className="p-3">
				<div className="flex flex-col gap-2 items-center w-full max-w-5xl mx-auto">
					<img
						src="/images/user-agent.svg"
						alt="user-agent"
						className="w-40 mt-10"
					/>
					<div className="uppercase text-center font-lg font-semibold text-[#00A1C4]">
						{t('general.menu_user_agent')}
					</div>
					<p className="max-w-md text-[#4A5571]">
						{t('user_agent.desc')}
					</p>
					<div className="grid grid-cols-1 md:grid-cols-3 border-y mt-4 border-x border-[#6FA5AE] rounded-lg overflow-hidden">
						<div className="flex flex-row gap-3 items-center p-3 bg-[#D6EAEC]">
							<div className="text-[#0A1A44]">
								<div className="uppercase text-lg font-semibold py-2 ">
									{t('user_agent.user_agent_title')}:
								</div>
								<div>{agentData?.useragent}</div>
							</div>
							<div className="border-r h-full border-[#6FA5AE]"></div>
							<div>
								<OverlayTrigger
									placement={"top"}
									overlay={
										<Tooltip
											id={`tooltip-top`}
											style={{
												width: "150px",
												textAlign: "center",
											}}
										>
											{isCopied
												? t('general.copied!')
												: t('general.copy_to_clipboard')}
										</Tooltip>
									}
								>
									<img
										src="/icons/copy.svg"
										alt="icon"
										className="w-12 cursor-pointer"
										onClick={() => {
											navigator.clipboard.writeText(
												agentData?.useragent
											);
											dispatch(UserAgentCopyAction({}))
											setCopied(true);
										}}
									/>
								</OverlayTrigger>
							</div>
						</div>
						<div className="col-span-1 md:col-span-2">
							<table className="w-full border-t md:border-t-0 md:border-l border-[#6FA5AE]">
								<tbody>
									<tr>
										<td className="py-2 border-b text-center font-semibold">
											{t('general.platform')}
										</td>
										<td className="py-2 border-b text-center border-l border-[#6FA5AE]">
											{agentData?.platform}
										</td>
									</tr>
									<tr>
										<td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
											{t('general.browser')}
										</td>
										<td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
											{getBrowserName()}
										</td>
									</tr>
									<tr>
										<td className="py-2 border-b text-center font-semibold">
											{t('general.screen_size')}
										</td>
										<td className="py-2 border-b text-center border-l border-[#6FA5AE]">
											{agentData?.screenSizeWidth} *{" "}
											{agentData?.screenSizeHeight}
										</td>
									</tr>
									<tr>
										<td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
											{t('general.cookie')}
										</td>
										<td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
											{agentData?.cookie}
										</td>
									</tr>
									<tr>
										<td className="py-2 text-center font-semibold">
											{t('general.javascript')}
										</td>
										<td className="py-2 text-center border-l border-[#6FA5AE]">
											{agentData?.javascript}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="flex flex-col gap-4 items-center p-2 w-full mt-1  ">
						<Button
							variant="info uppercase"
							style={{ backgroundColor: "#01B1BD", textTransform: "uppercase" }}
							onClick={handleShowRealAgent}
						>
							{realUserAgentVisible ? t('general.hide') : t('general.show')} {t('user_agent.real_user_agent')}
						</Button>
						{systemInfoLoader && (
							<ReactLoading type={"spin"} color="#01B1BD" />
						)}
						{realUserAgentVisible && !systemInfoLoader && (
							<div className="grid  border-y mt-1 border-x border-[#6FA5AE]  overflow-hidden">
								<div className="flex flex-row gap-3 items-center p-3 bg-[#D6EAEC]">
									<div className="text-[#0A1A44]">
										<div className="uppercase text-lg font-semibold py-2">
											{t('user_agent.your_real_ua')}:
										</div>
										<div>{readUserAgentSystemInfo}</div>
									</div>
									<div className="text-[#0A1A44]">
										<OverlayTrigger
											placement={"top"}
											overlay={
												<Tooltip
													id={`tooltip-top`}
													style={{
														width: "150px",
														textAlign: "center",
													}}
												>
													{isCopied
														? t('general.copied!')
														: t('general.copy_to_clipboard')}
												</Tooltip>
											}
										>
											<img
												src="/icons/copy.svg"
												alt="icon"
												className="w-12 cursor-pointer"
												onClick={() => {
													navigator.clipboard.writeText(
														readUserAgentSystemInfo
													);
													setCopied(true);
												}}
											/>
										</OverlayTrigger>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="flex flex-col gap-4 items-center border-y border-x border-[#0A1A44] rounded-lg p-2 w-full mt-5 py-4">
						<div className="uppercase text-lg font-semibold text-[#0A1A44]">
							{t('user_agent.ua_lookup_title')}
						</div>
						<p className="max-w-md text-[#4A5571]" dangerouslySetInnerHTML={{ __html: t('user_agent.ua_lookup_desc') }}></p>
						<div className="pt-2" id="input2">
							<div className="flex flex-row gap-3 rounded-lg bg-[#C2EFF3] px-3 py-2 my-4">
								<form
									onSubmit={(e) => {
										e.preventDefault();
										submitHandler();
									}}
								>
									<input
										type="text"
										className="bg-transparent border-none outline-none"
										placeholder="Search"
										onChange={(e) => {
											setUserAgentString(e.target.value);
											setShowError(false);
										}}
									/>
								</form>
								<Link
									to="input2"
									spy={true}
									smooth={true}
									duration={0}
									delay={0}
								>
									<img
										className="cursor-pointer"
										src="/icons/search.svg"
										alt="icon"
										onClick={() => submitHandler()}
									/>
								</Link>
							</div>
						</div>
					</div>
					<AdPlacement />
					<div id="result" className="w-full">
						{showError ? (
							<h4
								className="error-message"
								style={{ textAlign: "center", color: "red" }}
							>
								{Error}
							</h4>
						) : (
							""
						)}
						{showAgentLookupTable ? (
							<div className="w-full max-w-xl mx-auto rounded-lg border-x border-y border-[#6FA5AE] my-10">
								<table className="w-full table-fixed">
									<tbody>
										<tr>
											<td className="py-2 border-b text-center font-semibold">
												{t('general.menu_user_agent')}
											</td>
											<td className="py-2 border-b text-center border-l border-[#6FA5AE]">
												{lookupAgentData?.user_agent}
											</td>
										</tr>
										<tr>
											<td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
												{t('general.platform')}
											</td>
											<td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
												{lookupAgentData?.platform}
											</td>
										</tr>
										<tr>
											<td className="py-2 border-b text-center font-semibold">
												{t('general.browser')}
											</td>
											<td className="py-2 border-b text-center border-l border-[#6FA5AE]">
												{lookupAgentData?.browser}
											</td>
										</tr>
										<tr>
											<td className="py-2 border-b bg-[#D6EAEC] text-center font-semibold">
												{t('general.version')}
											</td>
											<td className="py-2 border-b bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
												{lookupAgentData?.version}
											</td>
										</tr>
										<tr>
											<td className="py-2 border-b text-center font-semibold">
												{t('general.operating_system')}
											</td>
											<td className="py-2 border-b text-center border-l border-[#6FA5AE]">
												{
													lookupAgentData?.operating_system
												}
											</td>
										</tr>
										<tr>
											<td className="py-2 bg-[#D6EAEC] text-center font-semibold">
												{t('general.screen_size')}
											</td>
											<td className="py-2 bg-[#D6EAEC] text-center border-l border-[#6FA5AE]">
												{lookupAgentData?.screen_size}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						) : null}
					</div>
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('user_agent.professional_care_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-1.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.professional_care_desc') }}></div>
					</div>
					<img
						src="/images/useragent/user-agent-page-img-1.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/useragent/user-agent-page-img-2.jpg"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('user_agent.what_is_ua_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-2.jpg"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.what_is_ua_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('user_agent.professional_benefit_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-3.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.professional_benefit_desc') }}></div>
					</div>
					<img
						src="/images/useragent/user-agent-page-img-3.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/useragent/user-agent-page-img-4.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('user_agent.most_popular_ua_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-4.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.most_popular_ua_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('user_agent.optimization_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-5.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.optimization_desc') }}></div>
					</div>
					<img
						src="/images/useragent/user-agent-page-img-5.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/useragent/user-agent-page-img-6.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('user_agent.risk_ua_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-6.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.risk_ua_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('user_agent.best_practice_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-7.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.best_practice_desc') }}></div>
					</div>
					<img
						src="/images/useragent/user-agent-page-img-7.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-white">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<img
						src="/images/useragent/user-agent-page-img-8.png"
						alt="home"
						className="rounded-xl hidden md:block"
					/>
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold text-[#0A1A44]">
							{t('user_agent.professional_care_ua_title')}
						</div>
						<img
							src="/images/useragent/user-agent-page-img-8.png"
							alt="home"
							className="rounded-xl md:hidden my-3"
						/>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.professional_care_ua_desc') }}></div>
					</div>
				</div>
				<div className="flex  mt-3  justify-center items-center max-w-5xl w-full mx-auto">
					{/* <h1>test</h1> */}
					<AdPlacementCustom adsStyle={{ ...adsStyle }} />
				</div>
			</section>

			<section className="px-3 py-20 bg-[#B3C6CB]  md:hidden">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 mx-auto w-full max-w-5xl">
					<div className="flex flex-col gap-2">
						<div className="uppercase text-3xl font-semibold">
							{t('user_agent.conclusion_title')}
						</div>
						<div dangerouslySetInnerHTML={{ __html: t('user_agent.conclusion_desc') }}></div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default UserAgent;
