import React from "react";

export default class AdPlacementCustom extends React.Component {
	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}

	render() {
		 
		return (
			<ins
				className="adsbygoogle"
				style={
					{
						display:window.innerWidth < 780 ?  "block":'inline-block',
						width: window.innerWidth < 780 ? this.props?.adsStyle?.width:"728px",
						height:  window.innerWidth < 780 ? this.props?.adsStyle?.height: "90px",
				  }
				}
				data-ad-client="ca-pub-3023471399174118"
				data-ad-slot="4742555558"
				data-full-width-responsive={window.innerWidth < 780 ?"false":"true"}
				data-ad-format= {window.innerWidth < 780 ?"vertical":"auto"}
			></ins>
		);
	}
}
