import ReactGA from "../googleAnalytics";

//HOME PAGE EVENTS
export const HP_Views = 'HP_Views';
export const HP_SignIn = 'HP_SignIn';
export const HP_Login = 'HP_Login';
export const HP_Logout = 'HP_Logout';
export const HP_IPSearch = 'HP_IPSearch';
export const HP_IPV4Copy = 'HP_IPV4Copy';
export const HP_IPV6Copy = 'HP_IPV6Copy';
export const HP_ShowHistory = 'HP_ShowHistory';
export const HP_AddHistory = 'HP_AddHistory';
export const HP_PreciseLocation = 'HP_PreciseLocation';
export const HP_CompleteIPDetails = 'HP_CompleteIPDetails';

// USER AGENT EVENTS
export const UA_Views = 'UA_Views';
export const UA_Copy = 'UA_Copy';
export const UA_ShowReal= 'UA_ShowReal';
export const UA_Lookup = 'UA_Lookup';

//SPEED TEST EVNETS
export const SpeedT_Views = 'SpeedT_Views';
export const SpeedT_Start = 'SpeedT_Start';

export const HPViewsAction = (data={}) => {
	ReactGA.event({ category: 'homepage', action: HP_Views })
	return { type: HP_Views, payload: data };
}

export const HPSignInAction = (data={}) => {
	return { type: HP_SignIn, payload: data };
}

export const HPLoginAction = (data={}) => {
	ReactGA.event({ category: 'homepage', action: HP_Login })
	return { type: HP_Login, payload: data };
}

export const HPLogoutAction = (data={}) => {
	ReactGA.event({ category: 'homepage', action: HP_Logout })
	return { type: HP_Logout, payload: data };
}

export const HPIPSearchAction = (data={}) => {
	ReactGA.event({ category: 'homepage', action: HP_IPSearch })
	return { type: HP_IPSearch, payload: data };
}

export const HPIPV4CopyAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_IPV4Copy })
	return { type: HP_IPV4Copy, payload: data };
}

export const HPIPV6CopyAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_IPV6Copy })
	return { type: HP_IPV6Copy, payload: data };
}

export const HPShowHistoryAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_ShowHistory })
	return { type: HP_ShowHistory, payload: data };
}

export const HPAddHistoryAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_AddHistory })
	return { type: HP_AddHistory, payload: data };
}

export const HPPreciseLocationAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_PreciseLocation }) 
	return { type: HP_PreciseLocation, payload: data };
}

export const HPCompleteIPDetailsAction = (data={}) => { 
	ReactGA.event({ category: 'homepage', action: HP_CompleteIPDetails }) 
	return { type: HP_CompleteIPDetails, payload: data };
}

export const UserAgentViewAction = (data={}) => { 
	ReactGA.event({ category: 'useragent', action: UA_Views }) 
	return { type: UA_Views, payload: data };
}

export const UserAgentCopyAction = (data={}) => { 
	ReactGA.event({ category: 'useragent', action: UA_Copy }) 
	return { type: UA_Copy, payload: data };
}

export const UserAgentShowRealAction = (data={}) => { 
	ReactGA.event({ category: 'useragent', action: UA_ShowReal }) 
	return { type: UA_ShowReal, payload: data };
}

export const UserAgentLookupAction = (data={}) => { 
	ReactGA.event({ category: 'useragent', action: UA_Lookup }) 
	return { type: UA_Lookup, payload: data };
}

export const SpeedTestViewAction = (data={}) => { 
	ReactGA.event({ category: 'speedtest', action: SpeedT_Views }) 
	return { type: SpeedT_Views, payload: data };
}

export const SpeedTesStartAction = (data={}) => { 
	ReactGA.event({ category: 'speedtest', action: SpeedT_Start }) 
	return { type: SpeedT_Start, payload: data };
}


